import { DefaultLayout } from "../../layouts";
import "./privacy.scss"
        
const Privacy = () => {

    return (
        <DefaultLayout 
            navBarProps={{links: [{name: "About", link: "/about"}, {name: "Projects", link: "/portfolio"}, {name: "Contacts", link: "/contacts"}]}}
            showFooter={true}>

            <div className="privacy-policies">

                <div className="section">

                    <p className="title">Privacy Policy for LusoMotors</p>

                    <p>Your privacy is important to us. It is LusoMotors's policy to respect your privacy regarding any information we may collect from you across our website, accessible from www.lusomotors.com, and other sites we own and operate.</p>
                    
                    <p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>

                    <p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification.</p>

                    <p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.</p>

                    <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>

                    <p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>

                    <p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>

                </div>

                <div className="section">

                    <p className="subtitle">
                        User's responsibilities
                    </p>
                    
                    <p>The user undertakes the responsibility to make appropriate use of the contents and information offered on the site with enunciative, but not imitative, behaviour:</p>

                    <p className="list-item">A) Not to engage in activities that are illegal or contrary to good faith and public order;</p>

                    <p className="list-item">B) Not to spread propaganda or content of a racist, xenophobic or gambling nature, any type of illegal pornography, terrorist claims or against human rights;</p>

                    <p className="list-item">C) Do not cause damage to physical systems (hardware) and unattainable (software) of LusoMotors, its suppliers or third parties, to introduce or disseminate computer viruses or any other hardware or software systems that are capable of causing damage previously mentioned.</p>

                </div>
                
                <div className="section">

                    <p className="subtitle">
                        More information
                    </p>
                    
                    <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</p>

                    <p>This policy is effective as of 16 May 2024 18:33.</p>

                </div>
                
            </div>

        </DefaultLayout>
    );
}

export default Privacy;