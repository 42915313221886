import React from "react";
import { LoadingContext } from "../contexts";

const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    function showLoading() {
        setIsLoading(true);
    }

    function hideLoading() {
        setIsLoading(false);
    }

    return (
        <LoadingContext.Provider value={{ isLoading, showLoading, hideLoading }}>
            {children}
        </LoadingContext.Provider>
    )

}

export default LoadingProvider;