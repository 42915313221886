import { Validator } from "../../helpers/utils";

export class ColorImage {
    tag: string;
    imageURL: string;
    
    constructor(tag: string, imageURL: string) {
        this.validateInputs(tag, imageURL);

        this.tag = tag;
        this.imageURL = imageURL;
    }

    private validateInputs(tag: string, imageURL: string): void {
        if (Validator.isStringNullOrEmpty(tag)) {
            throw new Error(`${this.constructor.name}: Tag must be provided and cannot be empty.`);
        }
        if (!Validator.isValidUrl(imageURL)) {
            throw new Error(`${this.constructor.name}: Invalid image URL '${imageURL}'.`);
        }
    }

    static fromJson({ json }: { json: any }): ColorImage {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const tag = json?.attributes?.tag;
        const imageURL = `${process.env.REACT_APP_STRAPI_API_BASE_URL}${json?.attributes?.image?.data?.attributes?.url}`;

        if (typeof tag !== 'string' || typeof imageURL !== 'string') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new ColorImage(tag, imageURL);
    }
}