import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useCountry } from "../contexts";

const CountryGuardedRoute = () => {
    const { country } = useCountry();
    const location = useLocation();

    return country === null
        ? <Navigate to='/' state={{ from: location }} replace />
        : <Outlet />
}

export default CountryGuardedRoute;