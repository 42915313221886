import { Validator } from "../../helpers/utils";
import { Dealer } from "./Dealer";

export class Country {
    id: number;
    code: string;
    name: string;
    ivaIncluded: boolean;
    dealer: Dealer | null;
    pricePerBox: number | null;

    constructor(id: number, code: string, name: string, ivaIncluded: boolean, dealer: Dealer | null, pricePerBox: number | null) {
        this.validateInput(id, code, name, ivaIncluded, pricePerBox);

        this.id = id;
        this.code = code;
        this.name = name;
        this.ivaIncluded = ivaIncluded;
        this.dealer = dealer;
        this.pricePerBox = pricePerBox;
    }

    private validateInput(id: number, code: string, name: string, ivaIncluded: boolean, pricePerBox: number | null): void {
    
        if (Validator.isNotANumber(id) || id <= 0) {
            throw new Error(`${this.constructor.name}: ID must be a positive integer.`);
        }
        if (Validator.isStringNullOrEmpty(code) || code.length !== 2) {
            throw new Error(`${this.constructor.name}: Code must be a alpha code of 2 characters.`);
        }
        if (Validator.isStringNullOrEmpty(name)) {
            throw new Error(`${this.constructor.name}: Name must be a non-empty string.`);
        }
        if (Validator.isStringNullOrEmpty(code) || code.length !== 2) {
            throw new Error(`${this.constructor.name}: Code must be a alpha code of 2 characters.`);
        }
        if (pricePerBox != null && (Validator.isNotANumber(pricePerBox) || pricePerBox <= 0)) {
            throw new Error(`${this.constructor.name}: Price must be a non-negative number.`);
        }
    }

    static fromJson({ json }: { json: any; }): Country {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const id = json?.id;
        const code = json?.attributes?.code;
        const name = json?.attributes?.name;
        const ivaIncluded = json?.attributes?.ivaIncluded;
        const dealer = json?.attributes?.dealer ? Dealer.fromJson({ json: json.attributes.dealer }) : null;
        const pricePerBox = json?.attributes?.shipping?.pricePerBox ?? null;

        if (typeof id !== 'number' || typeof code !== 'string' || typeof name !== 'string' || typeof ivaIncluded !== 'boolean' || (pricePerBox !== null && typeof pricePerBox !== 'number')) {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new Country(id, code, name, ivaIncluded, dealer, pricePerBox);
    }
}