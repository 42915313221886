import { Container } from "inversify";
import { BaquetService, GalleryService, OrderService, PortfolioService, RecaptchaService } from '../services';
import CountryService from "../services/CountryService";

const inversifyContainer = new Container();

// Bind services to the container
inversifyContainer.bind(GalleryService).toSelf().inSingletonScope();
inversifyContainer.bind(PortfolioService).toSelf().inSingletonScope();
inversifyContainer.bind(BaquetService).toSelf().inSingletonScope();
inversifyContainer.bind(RecaptchaService).toSelf().inSingletonScope();
inversifyContainer.bind(OrderService).toSelf().inSingletonScope();
inversifyContainer.bind(CountryService).toSelf().inSingletonScope();

export { inversifyContainer };
