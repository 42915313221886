import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Country } from '../../models/services/Country';
import './CountryDropdown.scss';

interface CountryDropdownProps {
    countryOptions: Country[]; 
    callback: (event: React.SyntheticEvent<HTMLElement, Event>, data: any) => void;
}

const CountryDropdown: React.FC<CountryDropdownProps> = ({ countryOptions, callback }) => {
    const countries = countryOptions 
        ? countryOptions.map((country: Country, index: number) => ({
            key: index,
            value: country.name,
            flag: country.code,
            text: country.name,
        }))
        : [];

    return (
        <div className='dropdown_container'>
            <Dropdown
                className="country_dropdown"
                placeholder='Select Country'
                fluid
                search
                selection
                options={countries}
                onChange={callback}
            />
        </div>
    );
};

export default CountryDropdown;
