import { Validator } from "../../helpers/utils";

export class Dealer {
    email: string;
    entity: string;

    constructor(email: string, entity: string) {
        this.validateInput(email, entity);

        this.email = email;
        this.entity = entity;
    }

    private validateInput(email: string, entity: string): void {
        if (email !== null && !Validator.isValidEmail(email)) {
            throw new Error(`${this.constructor.name}: Email must be a valid email address.`);
        }
        if (Validator.isStringNullOrEmpty(entity)) {
            throw new Error(`${this.constructor.name}: Entity must be a non-empty string.`);
        }
    }

    static fromJson({ json }: { json: any; }): Dealer {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const email = json?.email;
        const entity = json?.entity;

        if (typeof email !== 'string' || typeof entity !== 'string') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new Dealer(email, entity);
    }
}