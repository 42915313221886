import { Validator } from "../../helpers/utils";

export class PortfolioItem {
    public name: string;
    public description: string;
    public imagesUrls: string[];

    constructor(name: string, description: string, imagesUrls: string[]) {
        this.validateInputs(name, description, imagesUrls);

        this.name = name;
        this.description = description;
        this.imagesUrls = imagesUrls
    }

    private validateInputs(name: string, description: string, imagesUrls: string[]): void {
        if (Validator.isStringNullOrEmpty(name)) {
            throw new Error(`${this.constructor.name}: Name must be a non-empty string.`);
        }
        if (Validator.isStringNullOrEmpty(description)) {
            throw new Error(`${this.constructor.name}: Description must be a non-empty string.`);
        }
        if (imagesUrls !== null && imagesUrls.some(imageUrl => !Validator.isValidUrl(imageUrl))) {
            throw new Error(`${this.constructor.name}: Images URLs must be valid URLs.`);
        }
    }

    static fromJson({ json }: { json: any; }): PortfolioItem {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const name = json?.attributes?.name;
        const description = json?.attributes?.description;
        const baquetImages = json?.attributes?.images?.data?.map((image: any) => {
            if (image?.attributes?.formats?.medium?.url) {
                return `${process.env.REACT_APP_STRAPI_API_BASE_URL}${image?.attributes?.formats?.medium?.url}`;
            }
            else {
                return `${process.env.REACT_APP_STRAPI_API_BASE_URL}${image?.attributes?.url}`;
            }
        });

        if (typeof name !== 'string' || typeof description !== 'string' || !Array.isArray(baquetImages)) {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new PortfolioItem(name, description, baquetImages);
    }
}