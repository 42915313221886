import Lottie from "react-lottie"
import { useNavigate } from "react-router-dom"
import { DefaultLayout } from "../../../layouts"
import "./../../../assets/scss/general.scss"
import "./error.scss"

const NotFoundErrorPage = () => {

    // navigation
    const navigate = useNavigate();

    // lottie animation
    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: require("../../../assets/lottie/404_animation.json"), // your imported animation data
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <DefaultLayout 
            navBarProps={{links: [{name: "About", link: "/about"}, {name: "Projects", link: "/portfolio"}, {name: "Contacts", link: "/contacts"}]}}
            showFooter={true}>

            <div className="error">

                <div className="left-column">

                    <span className="error-code">ERROR 404</span>
                    
                    <span className="error-message">We can't seem to find the page you're looking for.</span>
                    
                    <div className="error-buttons">

                        <button className="button"
                            onClick={() => navigate(-1)}>
                            GO BACK
                        </button>

                        <button className="button"
                            onClick={() => navigate("/")}>
                            GO HOME
                        </button>

                    </div>

                </div>

                <div className="lottie-animation">
                    <Lottie
                        options={animationOptions}
                        height={350}
                        width={350}
                    />
                </div>
                
            </div>
        </DefaultLayout>
    );
}

export default NotFoundErrorPage;