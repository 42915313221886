import { injectable } from "inversify";
import { ErrorCodes } from "../helpers/enums";
import { DataMapper } from "../helpers/utils";
import { Country } from "../models/services/Country";
import { CustomError } from "../models/services/CustomError";
import BaseService from "./BaseService";

/**
 * Service for managing countries.
 */
@injectable()
class CountryService extends BaseService {
    private readonly RESOURCE_PATH : string = "/countries";
    private cachedCountries: Country[] | null = null;

    /**
     * Retrieves the list of countries.
     * @returns A promise that resolves with an array of Country objects.
     * @throws CustomError if an unexpected error occurs during the request.
     */
    public async getCountries(): Promise<Country[]> {
        if (this.cachedCountries !== null) {
            return this.cachedCountries;
        }

        return await this.httpClient.get(`${this.RESOURCE_PATH}?sort[0]=name&populate=deep`)
        .then((response) => {
            return response?.data;
        })
        .then((strapiResponse) => {
            const countries: Country[] = DataMapper.createFromData(strapiResponse?.data, Country.fromJson);

            if (countries.length > 0) {
                this.cachedCountries = countries;
            }

            return countries;
        })
        .catch(error => {
            if (error instanceof CustomError) {
                throw error;
            } else {
                throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred: ${error.message}`, error);
            }
        });
    }

    /**
     * Retrieves the list of countries with a dealer.
     * @returns A promise that resolves with an array of Country objects.
     * @throws CustomError if an unexpected error occurs during the request.
     */
    public async getDealerCountries(): Promise<Country[]> {
        return this.getCountries()
        .then((countries) => {
            return countries.filter((country) => country.dealer !== null);
        })
        .catch(error => {
            if (error instanceof CustomError) {
                throw error;
            } else {
                throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred: ${error.message}`, error);
            }
        });
    }
}

export default CountryService;