import aboutData from "../../data/about.json";
import { DefaultLayout } from "../../layouts";
import "./about.scss";

const About = () => {

    // parse the json to get the text
    var dataJson = JSON.parse(JSON.stringify(aboutData));
    var text = 'lusomotors' in dataJson ? dataJson['lusomotors'].paragraphs : 'About text not found!'

    return (
        <DefaultLayout 
            navBarProps={{links: [{name: "About", link: "/about"}, {name: "Projects", link: "/portfolio"}, {name: "Contacts", link: "/contacts"}]}} 
            showFooter={true}>
            <div className='about-container'>
                <div className="content">

                    <img className='company-logo' src={'lusomotors_shield.png'} alt="company-logo"/>

                    <span className='greetings regular-text'>Welcome to LusoMotors</span>

                    {
                        Object.entries(text).map(([key, value]) => (
                            <span className='regular-text' key={key}>{value as string}</span>
                        ))
                    }

                    <div className="company-logos">
                        <img src="/rcc.png" alt="RCC Logo"/>
                        <img src="/isettaatelier.png" alt="Isetta Logo"/>
                        <img src="/talbot.png" alt="Talbot Logo"/>
                        <img src="/efs.png" alt="EFS Logo"/>
                    </div>
                    
                </div>
            </div>
        </DefaultLayout>
    );
}

export default About;