import { Validator } from "../../helpers/utils";

export class Finishing {
    id: number;
    name: string;
    price: number;
    imageURL: string;

    constructor(id: number, name: string, price: number, imageURL: string) {
        this.validateInputs(id, name, price, imageURL);

        this.id = id;
        this.name = name;
        this.price = price;
        this.imageURL = imageURL;
    }

    private validateInputs(id: number, name: string, price: number, imageURL: string): void {
        if (Validator.isNotANumber(id) || id <= 0) {
            throw new Error(`${this.constructor.name}: ID must be a positive integer.`);
        }
        if (Validator.isStringNullOrEmpty(name)) {
            throw new Error(`${this.constructor.name}: Name must be a non-empty string.`);
        }
        if (Validator.isNotANumber(price) || price < 0) {
            throw new Error(`${this.constructor.name}: Price must be a positive number.`);
        }
        if (!Validator.isValidUrl(imageURL)) {
            throw new Error(`${this.constructor.name}: Invalid image URL '${imageURL}'.`);
        }
    }

    static fromJson({ json }: { json: any; }): Finishing {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const id = json?.id;
        const name = json?.attributes?.name;
        const price = json?.attributes?.price;
        const imageURL = `${process.env.REACT_APP_STRAPI_API_BASE_URL}${json?.attributes?.image?.data?.attributes?.url}`;

        if (typeof id !== 'number' || typeof name !== 'string' || typeof price !== 'number' || typeof imageURL !== 'string') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new Finishing(id, name, price, imageURL);
    }
}