import { Validator } from "../../helpers/utils";

export class Size {
    id: number;
    abbreviation: string;
    meaning: string;
    price: number;

    constructor(id: number, abbreviation: string, meaning: string, price: number) {
        this.validateInputs(id, abbreviation, meaning, price);

        this.id = id
        this.abbreviation = abbreviation;
        this.meaning = meaning;
        this.price = price;
    }

    private validateInputs(id: number, abbreviation: string, meaning: string, price: number): void {
        if (Validator.isNotANumber(id) || id <= 0) {
            throw new Error(`${this.constructor.name}: ID must be a positive integer.`);
        }
        if (Validator.isStringNullOrEmpty(abbreviation)) {
            throw new Error(`${this.constructor.name}: Abbreviation must be provided and cannot be empty.`);
        }
        if (Validator.isStringNullOrEmpty(meaning)) {
            throw new Error(`${this.constructor.name}: Meaning must be provided and cannot be empty.`);
        }
        if (Validator.isNotANumber(price) || price < 0) {
            throw new Error(`${this.constructor.name}: Price must be a non-negative number.`);
        }
    }

    static fromJson({ json }: { json: any }): Size {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const id = json?.id;
        const abbreviation = json?.attributes?.abbreviation;
        const meaning = json?.attributes?.meaning;
        const price = json?.attributes?.price;

        if (typeof id !== 'number' || typeof abbreviation !== 'string' || typeof meaning !== 'string' || typeof price !== 'number') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new Size(id, abbreviation, meaning, price);
    }
}