export default class Validator {
    static isNotANumber(value: any): boolean {
        return typeof value !== 'number' || isNaN(value);
    }

    static isStringNullOrEmpty(value: string): boolean {
        return typeof value === 'string' && value.trim().length === 0;
    }

    static isValidUrl(url: string): boolean {
        const urlPattern: RegExp = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return typeof url === 'string' && urlPattern.test(url);
    }

    static isValidRccIdentifier(identifier: string): boolean {
        const identifierPattern: RegExp = /^rcc-[a-z0-9-]+$/;
        return typeof identifier === 'string' && identifierPattern.test(identifier.toLowerCase());
    }

    static isArray(value: any[]): boolean {
        return Array.isArray(value);
    }

    static isValidEmail(email: string): boolean {
        const emailPattern: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return typeof email === 'string' && emailPattern.test(email);
    }

    static isValidHexCode(hexCode: string): boolean {
        const hexCodePattern: RegExp = /^[0-9a-fA-F]{6}$/;
        return typeof hexCode === 'string' && hexCodePattern.test(hexCode);
    }
}
