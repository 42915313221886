import { injectable } from "inversify";
import { ErrorCodes } from "../helpers/enums";
import { DataMapper } from "../helpers/utils";
import { CustomError } from "../models/services/CustomError";
import { PortfolioItem } from "../models/services/PortfolioItem";
import BaseService from "./BaseService";

/**
 * Service class for managing portfolio items.
 */
@injectable()
class PortfolioService extends BaseService {
    private readonly RESOURCE_PATH : string = "/portfolios";

    /**
     * Retrieves portfolio items from the server.
     * @returns A promise that resolves to an array of PortfolioItem objects.
     */
    public async getPorfolioItems(): Promise<PortfolioItem[]> {

        return this.httpClient.get(`${this.RESOURCE_PATH}?fields[0]=name&fields[1]=description&populate=deep&sort=id`)
        .then((response) => {
            return response?.data;
        })
        .then((strapiResponse) => {
            return DataMapper.createFromData(strapiResponse?.data, PortfolioItem.fromJson);
        })
        .catch(error => {
            if (error instanceof CustomError) {
                throw error;
            } else {
                throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred: ${error.message}`, error);
            }
        });
    }
}

export default PortfolioService;