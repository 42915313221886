import { injectable } from "inversify";
import { ErrorCodes } from "../helpers/enums";
import { CustomError } from "../models/services/CustomError";
import BaseService from "./BaseService";

/**
 * Service class validating the recaptcha token.
 */
@injectable()
class RecaptchaService extends BaseService {
    private readonly RESOURCE_PATH : string = "/shop";

    /**
     * Validates the reCAPTCHA token.
     * @param token The reCAPTCHA token to validate.
     * @throws {CustomError} If the reCAPTCHA token is invalid or the validation fails.
     */
    public async validateRecaptcha(token: string): Promise<void> {
        if (!token || token === '') {
            throw new CustomError(ErrorCodes.INVALID_RECAPTCHA, "No reCAPTCHA validation performed.");
        }

        if (token === 'token-expired') {
            throw new CustomError(ErrorCodes.INVALID_RECAPTCHA, "Your reCAPTCHA verification expired. Please, validate the reCAPTCHA again.");
        }

        await this.httpClient.post(`${this.RESOURCE_PATH}/validate-recaptcha`, {token: token})
        .then(response => {
            return response.data;
        })
        .then(data => {
            if (data.message === undefined || data.message === null) {
                throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred while validating reCAPTCHA. Response message: ${data.message}`);
            }
            if (data.message !== 'reCAPTCHA validation successful') {
                throw new CustomError(ErrorCodes.SERVER_ERROR, 'No message received from server.');
            }
        })
        .catch(error => {
            if (error instanceof CustomError) {
                throw error;
            } else {
                throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred: ${error.message}`, error);
            }
        });
    }
}

export default RecaptchaService;