import { injectable } from "inversify";
import { ErrorCodes } from "../helpers/enums";
import { CustomError } from "../models/services/CustomError";
import BaseService from "./BaseService";

/**
 * GalleryService class provides methods to interact with the gallery-related functionalities.
 * 
 * @extends {BaseService}
 */
@injectable()
class GalleryService extends BaseService {
    private readonly RESOURCE_PATH : string = "/galleries";
    private cachedGalleryElements: Map<string, string[]> | null = null;

    /**
     * Retrieves gallery contents asynchronously.
     *
     * If cached gallery elements exist, it returns the cached data. Otherwise, it fetches
     * gallery contents from the server using the provided HTTP client.
     *
     * @returns {Promise<Map<string, string[]>>} A Promise resolving to a Map containing
     *                                               gallery elements where keys are baquet names
     *                                               and values are arrays of image URLs.
     */
    public async getGalleryContents(): Promise<Map<string, string[]>> {
        if (this.cachedGalleryElements !== null) {
            return this.cachedGalleryElements;
        }

        return this.httpClient.get(`${this.RESOURCE_PATH}?fields[0]=baquetName&populate=deep`)
        .then((response) => {
            return response?.data;
        })
        .then((strapiResponse) => {
            const galleryElements: Map<string, Array<string>> = new Map();

            if (strapiResponse?.data) {
                strapiResponse?.data.forEach((galleryElement: any) => {
                    let baquetName = galleryElement?.attributes?.baquetName;
                    let baquetImages = galleryElement?.attributes?.images?.data?.map((image: any) => {
                        if (image?.attributes?.formats?.medium?.url) {
                            return `${process.env.REACT_APP_STRAPI_API_BASE_URL}${image?.attributes?.formats?.medium?.url}`;
                        }
                        else {
                            return `${process.env.REACT_APP_STRAPI_API_BASE_URL}${image?.attributes?.url}`;
                        }
                    });

                    if (baquetName && baquetImages) {
                        galleryElements.set(baquetName, baquetImages);
                    }
                });

                if (galleryElements.size > 0) {
                    this.cachedGalleryElements = galleryElements;
                }
            }

            return galleryElements;
        })
        .catch(error => {
            if (error instanceof CustomError) {
                throw error;
            } else {
                throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred: ${error.message}`, error);
            }
        });
    }
}

export default GalleryService;