import { Validator } from "../../helpers/utils";

export class ColorHex {
    name: string;
    hexCode: string;
    catalogueCode: string;
    
    constructor(name: string, hexCode: string, catalogueCode: string) {
        this.validateInputs(name, hexCode, catalogueCode);

        this.name = name;
        this.hexCode = hexCode;
        this.catalogueCode = catalogueCode;
    }

    private validateInputs(name: string, hexCode: string, catalogueCode: string): void {
        if (Validator.isStringNullOrEmpty(name)) {
            throw new Error(`${this.constructor.name}: Name must be provided and cannot be empty.`);
        }
        if (!Validator.isValidHexCode(hexCode)) {
            throw new Error(`${this.constructor.name}: Hex code provided is invalid.`);
        }
        if (Validator.isStringNullOrEmpty(catalogueCode)) {
            throw new Error(`${this.constructor.name}: Catalogue code must be provided and cannot be empty.`);
        }
    }

    static fromJson({ json }: { json: any }): ColorHex {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const name = json?.attributes?.name;
        const hexCode = json?.attributes?.hexCode;
        const catalogueCode = json?.attributes?.catalogueCode;

        if (typeof name !== 'string' || typeof hexCode !== 'string' || typeof catalogueCode !== 'string') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new ColorHex(name, hexCode, catalogueCode);
    }
}