import Lottie from "react-lottie"
import { useNavigate } from "react-router-dom"
import { ErrorPageProps } from "../../../models/props/ErrorPageProps"
import "./../../../assets/scss/general.scss"
import "./error.scss"
import { memo } from "react"

const ErrorPage = memo((props : ErrorPageProps) => {

    // navigation
    const navigate = useNavigate();

    // lottie animation
    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: require("../../../assets/lottie/error_animation.json"),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (

        <div className="error">

            <div className="left-column">

                <span className="error-code">{'ERROR ' + props.title}</span>
                
                <span className="error-message">{props.message}</span>
                
                <div className="error-buttons">

                    <button className="button"
                        onClick={() => navigate(-1)}>
                        GO BACK
                    </button>

                    <button className="button"
                        onClick={() => navigate("/")}>
                        GO HOME
                    </button>

                </div>

            </div>

            <div className="lottie-animation">
                <Lottie
                    options={animationOptions}
                    height={350}
                    width={350}
                />
            </div>
            
        </div>
    );
});

export default ErrorPage;