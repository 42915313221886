import { DataMapper } from "../../helpers/utils";
import { Headrest } from "./Headrest";
import { Incompatibilities } from "./Incompatibilities";
import { Material } from "./Material";
import { Padding } from "./Padding";

export class Seat {
    material: Material;
    headrests: Headrest[];
    paddings: Padding[];
    incompatibilities: Incompatibilities | null;

    constructor(material: Material, headrests: Headrest[], paddings: Padding[], incompatibilities: Incompatibilities | null) {
        this.material = material;
        this.headrests = headrests;
        this.paddings = paddings;
        this.incompatibilities = incompatibilities;
    }

    static fromJson({ json }: { json: any }): Seat {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const material: Material = Material.fromJson({ json: json });
        const headrests: Headrest[] = DataMapper.createFromData(json?.headrests, Headrest.fromJson);
        const paddings: Padding[] = DataMapper.createFromData(json?.paddings?.data, Padding.fromJson);
        const incompatibilities: Incompatibilities | null = json?.incompatibilities !== null ? Incompatibilities.fromJson({ json: json?.incompatibilities }) : null;

        return new Seat(material, headrests, paddings, incompatibilities);
    }
}