
import { Provider } from "inversify-react";
import React from "react";
import { inversifyContainer } from "../../config/Inversify.config";

const InversifyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    return (
        <Provider key={inversifyContainer.id} container={inversifyContainer}>
            {children}
        </Provider>
    )

}

export default InversifyProvider;