import React, { useContext } from "react";
import { LoadingContextType } from "../../models/types";

const LoadingContext = React.createContext<LoadingContextType>({
    isLoading: false,
    showLoading: () => { },
    hideLoading: () => { },
});

export function useLoading() {
    return useContext(LoadingContext);
}

export default LoadingContext;
