import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha';
import { useParams } from 'react-router-dom';
import { InfoOverlay } from "../../../components/overlay/infoOverlay";
import { PopUp } from "../../../components/popUp/popUp";
import { useCountry, useLoading } from "../../../helpers/contexts";
import { DefaultLayout } from "../../../layouts";
import { Baquet } from "../../../models/services/Baquet";
import { BaquetOrder } from "../../../models/services/BaquetOrder";
import { Mount } from "../../../models/services/Mount";
import { BaquetService } from "../../../services";
import OrderService from "../../../services/OrderService";
import RecaptchaService from "../../../services/RecaptchaService";
import { Customer } from '../../../models/services/Customer';
import { toBase64 } from '../../../helpers/utils/FileUtils';
import { CustomError } from '../../../models/services/CustomError';
import { ErrorCodes } from '../../../helpers/enums';
import { Validator } from '../../../helpers/utils';
import { ColorHex } from '../../../models/services/ColorHex';
import { getErrorInformation } from '../../../helpers/handlers/GlobalErrorHandler';
import ErrorPage from '../../error/generic/error';
import { ErrorPageProps } from '../../../models/props/ErrorPageProps';
import "./../../../assets/scss/general.scss";
import "./seat.scss";

const Seat = () => {

    // props
    const { id } = useParams<{ id: string }>();

    // contexts
    const { country } = useCountry();
    const { showLoading, hideLoading } = useLoading();

    // services
    const baquetService: BaquetService = useInjection<BaquetService>(BaquetService);
    const recaptchaService: RecaptchaService = useInjection<RecaptchaService>(RecaptchaService);
    const orderService: OrderService = useInjection<OrderService>(OrderService);

    // order information
    const [order, setOrder] = useState<BaquetOrder|null>(null);

    // page information
    const [baquet, setBaquet] = useState<Baquet|null>(null);
    const [availableExtras, setAvailableExtras] = useState<number[]>([]);
    const [availableMaterials, setAvailableMaterials] = useState<number[]>([]);
    const [allMounts, setAllMounts] = useState<Mount[]>([]);
    const [availableMounts, setAvailableMounts] = useState<Mount[]>([]);
    const [selectedMounts, setSelectedMounts] = useState<Mount[]>([]);
    const [showHeadrestImageUpload, setShowHeadrestImageUpload] = useState<boolean>(false);
    const [showCustomerForm, setShowCustomerForm] = useState<boolean>(true);
    const maxFileSize: number = 10 * 1024 * 1024; // 10MB limit

    // price information
    const [price, setPrice] = useState<number>(0);
    const [taxPrice, setTaxPrice] = useState<number>(0);
    const [addTax, setAddTax] = useState<boolean>(false);
    const taxPercentage: number = 0.23;
    const currency: string = '€';

    // recaptcha
    const [token, setToken] = useState<string>('');
    
    // pop up
    const [popUpIsOpen, setPopUpIsOpen] = useState<boolean>(false);
    const [popUpTitle, setPopUpTitle] = useState<string>("");
    const [popUpMessage, setPopMessage] = useState<string>("");
    const [popUpSuccess, setPopSuccess] = useState<boolean>(false);

    // error
    const [showError, setShowError] = useState<boolean>(false);
    const [errorProps, setErrorProps] = useState<ErrorPageProps>({title: "Ups!", message: "Something went wrong. Please try again later."});

    /**
     *  Gets the baquet information based on the id and sets the initial order configuration
     *  Runs when the page first loads
    */
    useEffect(() => {
        showLoading();
        if (id && isNaN(parseInt(id)) === false && country){
            baquetService.getBaquet(Number(id))
            .then(responseBaquet => {
                //console.log(responseBaquet);
                setBaquet(responseBaquet);
                getAllMounts(responseBaquet);
                setAvailableMounts(allMounts);
                setOrder(new BaquetOrder(
                    Number(id),                              // model id
                    1,                                       // quantity
                    responseBaquet.sizes[0].id,              // size id
                    responseBaquet.finishings[0].id,         // finishing id
                    null,                                    // finishing ral code
                    [],                                      // extras ids
                    responseBaquet.seats[0].material.id,     // material id
                    responseBaquet.seats[0].paddings[0].id,  // padding id
                    responseBaquet?.seats[0]?.headrests[0]?.id !== undefined ? responseBaquet?.seats[0]?.headrests[0]?.id : null, // headrest id
                    null,                                    // headrest image
                    responseBaquet?.seats[0]?.material?.paddingColors[0]?.tag !== undefined ? responseBaquet.seats[0].material.paddingColors[0].tag : null, // padding color tag
                    getDefaultStitchingColor(responseBaquet),                                                                                               // stitching color tag
                    responseBaquet?.seats[0]?.material.liningColors[0]?.tag !== undefined ? responseBaquet.seats[0].material.liningColors[0].tag : null,    // lining color tag
                    [],                                                   // mounts ids
                    '',                                                   // observations
                    new Customer('', '', country.code, '', '', '', false) // customer
                ));
                if (country.ivaIncluded) {
                    setAddTax(true);
                }
                if (country?.dealer && country?.dealer !== null && country?.dealer !== undefined) {
                    setShowCustomerForm(false);
                }
            })
            .catch(error => {
                const props: ErrorPageProps = getErrorInformation(error);
                setErrorProps(props);
                setShowError(true);
                console.log(error);
            })
            .finally(() => {
                hideLoading();
            })
        }
        else {
            const props: ErrorPageProps = getErrorInformation(new CustomError(ErrorCodes.RESOURCE_NOT_FOUND, "The parameter provided is invalid.", undefined));
            setErrorProps(props);
            setShowError(true);
        }
        // eslint-disable-next-line
    }, []);

    /** 
     *  Updates the price of the order
     *  Runs when the order is altered
    */
    useEffect(() => {
        updateOrderPrice();
        // eslint-disable-next-line
    }, [order]);

    /**
     *  Updates the price of the order based on the selected configuration
     *  @returns { void }
    */
    function updateOrderPrice() : void {
        if (baquet && order) {
            // base price
            let newPrice = baquet.basePrice;
            // size price
            let sizePrice = baquet.sizes.find(item => item.id === order.sizeId)?.price;
            if (sizePrice) {
                newPrice = newPrice + sizePrice;
            }
            // finishing price
            let orderFinishing = baquet?.finishings.find(item => item.id === order.finishingId);
            if (orderFinishing) {
                newPrice = newPrice + orderFinishing?.price;
            }
            // extras price
            let orderExtras = baquet?.extras.filter(item => order.extrasIds.includes(item.id));
            if (orderExtras) {
                orderExtras.forEach(extra => {
                    newPrice = newPrice + extra.price;
                });
            }
            // padding price
            let orderPadding = baquet?.seats.find(item => item.material.id === order.materialId)?.paddings.find(item => item.id === order.paddingId);
            if (orderPadding) {
                newPrice = newPrice + orderPadding.price;
            }
            // headrest price
            let orderHeadrest = baquet?.seats.find(item => item.material.id === order.materialId)?.headrests.find(item => item.id === order.headrestId);
            if (orderHeadrest) {
                newPrice = newPrice + orderHeadrest.price;
            }
            // mounts price
            selectedMounts.forEach(mount => {
                newPrice = newPrice + mount.price;
            });

            // quantity price
            newPrice = newPrice * order.quantity;

            // shipping price
            newPrice = newPrice + calculateShippingPrice();

            // tax price
            if (addTax && order.customer && order.customer.isCompany === false) {
                let newTaxPrice = newPrice * taxPercentage;
                setTaxPrice(parseFloat(newTaxPrice.toFixed(2)));
                newPrice = newPrice + newPrice * taxPercentage;
            }
            else {
                setTaxPrice(0);
            }

            newPrice = parseFloat(newPrice.toFixed(2));

            setPrice(newPrice);
        }
    }

    /**
     *  Updates the available material options
     *  Runs when the selected size is altered
    */
    useEffect(() => {
        changeMaterials();
        // eslint-disable-next-line
    }, [order?.sizeId]);

    /**
     *  Updates the available material options based on the selected size
     *  @returns { void }
    */
    function changeMaterials() : void{
        let newAvailableMaterials: Array<number> = [];
        baquet?.seats.forEach(seat => {
            // check if selectedSize is in the size incompatibilities of the material
            if (!seat.incompatibilities || (seat.incompatibilities && !seat.incompatibilities.sizes.some(item => item === order?.sizeId))) {
                newAvailableMaterials.push(seat.material.id);
            }
        });
        if (order) {
            const updatedOrder = { ...order, materialId: newAvailableMaterials[0]};
            setOrder(updatedOrder);
        }
        setAvailableMaterials(newAvailableMaterials);
    }

    /**
     *  Updates the selected material
     *  Runs when the available materials are altered
    */
    useEffect(() => {
        if (order && !availableMaterials.includes(order?.materialId)) {
            const updatedOrder = { ...order, materialId: availableMaterials[0] };
            setOrder(updatedOrder);
        }
        // eslint-disable-next-line
    }, [availableMaterials]);

    /**
     *  Updates the available extras options and the selected padding design
     *  Runs when the selected material is altered
    */
    useEffect(() => {
        showLoading();
        changeExtras();
        // update the selected padding design
        let newAvailablePaddings = baquet?.seats.find(item => item.material.id === order?.materialId)?.paddings;
        if (order && newAvailablePaddings && newAvailablePaddings.length > 0) {
            if (!newAvailablePaddings.some(item => item.id === order.paddingId)) {
                const updatedOrder = { ...order, paddingId: newAvailablePaddings[0].id};
                setOrder(updatedOrder);
            }
        }
        hideLoading();
        // eslint-disable-next-line
    }, [order?.materialId]);

    /**
     *  Updates the available extras options based on the selected material
     *  @returns { void }
    */
    function changeExtras() : void {
        let newAvailableExtras: Array<number> = getAllExtras();
        baquet?.seats.filter(item => item.material.id === order?.materialId)[0]?.incompatibilities?.extras.forEach(extraId => {
            newAvailableExtras = newAvailableExtras.filter(item => item !== extraId);
        });
        setAvailableExtras(newAvailableExtras);
    }

    /**
     *  Updates the available mounting options
     *  Runs when the baquet mounts are altered
    */
    useEffect(() => {
        setAvailableMounts(allMounts);
    }, [allMounts]);

    /**
     *  Updates the available mounting options
     *  Runs when the selected mounts are altered
    */
    useEffect(() => {
        if (baquet) {
            changeMounts();
        }
        if (selectedMounts) {
            let selectedMountsIds: number[] = [];
            selectedMounts.forEach(mount => {
                if (mount.id) {
                    selectedMountsIds.push(mount.id);
                }
            });
            if (order) {
                const updatedOrder = { ...order, mountsIds: selectedMountsIds};
                setOrder(updatedOrder);
            }
        }
        // eslint-disable-next-line
    }, [selectedMounts]);

    /**
     *  Sets all the mounting options as available
     *  @param { Baquet } baquet - the baquet object with all the information about the model
     *  @returns { void }
    */
    function getAllMounts(baquet: Baquet) : void {
        let allMounts: Array<Mount> = [];
        baquet?.mountingOptions.forEach(option => {
            option.mounts.forEach(mount => {
                if (!allMounts.some(item => item.identifier === mount.identifier)) {
                    allMounts.push(mount);
                }
            })
        })
        setAllMounts(allMounts);
    }

    /**
     *  Returns all the extras options from the baquet model
     *  @returns { Array<number> } allExtras - an array with the ids from all the extras options
    */
    function getAllExtras() : number[] {
        let allExtras: Array<number> = [];
        baquet?.extras.forEach(extra => {
            allExtras.push(extra.id);
        })
        return allExtras;
    }

    /**
     *  Adds or removes an extra option from the order based on the checkbox status
     *  @param { number } extraId - the id of the extra option
     *  @returns { void }
    */
    function updateExtras(extraId: number) : void {
        let extra = baquet?.extras.find(item => item.id === extraId);
        var checkBox = document.getElementById("extra-check-" + extraId) as HTMLInputElement;
        let newSelectedExtras: number[] = [];
        if (order && extra) {
            if (checkBox && checkBox.checked){
                newSelectedExtras.push(extra.id);
            }
            const updatedOrder = { ...order, extrasIds: newSelectedExtras};
            setOrder(updatedOrder);
        }
        else {
            console.log('error: extra not found');
        }
    }

    /**
     *  Returns the catalogue code of the default selected stitching color when the page first loads
     *  @param { Baquet } baquet - the baquet object with all the information about the model
     *  @returns { string | null } - the catalogue code of the default selected stitching color
    */
    function getDefaultStitchingColor(baquet: Baquet) : string | null {
        // get the white stitching color
        let whiteStitching = baquet?.seats[0]?.material?.stitchingColors?.find(item => item.hexCode === 'ffffff');
        if (whiteStitching === undefined) {
            // get the first stitching color 
            let firstStitching = baquet?.seats[0]?.material?.stitchingColors[0];
            if (firstStitching !== undefined) {
                return firstStitching.catalogueCode;
            }
            else {
                return null;
            }
        }
        else {
            return whiteStitching.catalogueCode;
        }
    }

    /**
     *  Updates the selected colors based on a change in material
     *  @param { number } materialId - id of the new selected material
     *  @returns { BaquetOrder | null } updatedOrder - the order object with the selected colors updated
    */
    function updateSelectedColors(materialId: number) : BaquetOrder | null {
        // get the new material
        let newMaterial = baquet?.seats.find(item => item.material.id === materialId)?.material;
        if (order && newMaterial) {
            let updatedOrder = { ...order };
            // check if the current padding color is null or not available for the new material
            if (order?.paddingColorTag === null || newMaterial.paddingColors.find(item => item.tag === order?.paddingColorTag) === undefined) {
                // if so, check if there are available padding colors for the new material
                if (newMaterial?.paddingColors.length > 0 && newMaterial?.paddingColors[0]?.tag) {
                    // if there are, select the first available padding color
                    updatedOrder.paddingColorTag = newMaterial.paddingColors[0].tag;
                }
                else {
                    // if there are no padding colors available, set the padding color to null
                    updatedOrder.paddingColorTag = null;
                }
            }
            // check if the current stitching color is null or not available for the new material
            if (order?.stitchingColorTag === null || newMaterial.stitchingColors.find(item => item.catalogueCode === order?.stitchingColorTag) === undefined) {
                // if so, check if there are available stitching colors for the new material
                if (newMaterial?.stitchingColors.length > 0 && newMaterial?.stitchingColors[0]?.catalogueCode) {
                    // if there are, check if the white stitching color is available
                    let whiteStitching = newMaterial?.stitchingColors.find(item => item.hexCode === 'ffffff');
                    if (whiteStitching !== undefined) {
                        // if it is, select the white stitching color
                        updatedOrder.stitchingColorTag = whiteStitching.catalogueCode;
                    }
                    else {
                        // if not, select the first available stitching color
                        updatedOrder.stitchingColorTag = newMaterial?.stitchingColors[0]?.catalogueCode;
                    }
                }
                else {
                    // if there are no stitching colors available, set the stitching color to null
                    updatedOrder.stitchingColorTag = null;
                }
            }
            // check if the current lining color is null or not available for the new material
            if (order?.liningColorTag === null || newMaterial.liningColors.find(item => item.tag === order?.liningColorTag) === undefined) {
                // if so, check if there are available lining colors for the new material
                if (newMaterial?.liningColors.length > 0 && newMaterial?.liningColors[0]?.tag) {
                    // if there are, select the first available lining color
                    updatedOrder.liningColorTag = newMaterial.liningColors[0].tag;
                }
                else {
                    // if there are no lining colors available, set the lining color to null
                    updatedOrder.liningColorTag = null;
                }
            }
            return updatedOrder;
        }
        else {
            return null;
        }
    }

    /**
     *  Sorts the available stitching colors based on the hexadecimal code
     *  @returns { ColorHex[] } result - array with the sorted stitching colors
    */
    function sortStitchingColors(): ColorHex[] {
        let colors = baquet?.seats?.find(item => item.material.id === order?.materialId)?.material.stitchingColors;
        let result: ColorHex[] = [];
        if (colors !== undefined && colors != null) {
            let colorsAux = colors.map((color) => color.hexCode);
            // Step 1: Convert hexadecimal colors to integers
            const colorIntegers = colorsAux.map(color => parseInt(color.replace('#', ''), 16));
            // Step 2: Sort the array of integers
            colorIntegers.sort((a, b) => b - a);
            // Step 3: Convert sorted integers back to hexadecimal colors
            const sortedColors = colorIntegers.map(colorInt => colorInt.toString(16).padStart(6, '0'));
            // Step 4
            for (let i = 0; i < sortedColors.length; i++) {
                let newColor = colors.find(c => c.hexCode === sortedColors[i]);
                if (newColor !== undefined) {
                    result.push(newColor); 
                }
            }
        };
        //result.map(item => item.hexCode = '#' + item.hexCode);
        return result;
    }

    /**
     *  Checks if there are available headrests based on the selected material
     *  @returns { Boolean } - true if there are available headrests, false otherwise
    */
    function areHeadrestsAvailable(baquet: Baquet|null, selectedMaterial: number) : boolean {
        let headrests = baquet?.seats.find(item => item.material.id === selectedMaterial)?.headrests.length;
        if (headrests){
            return true;
        }
        else {
            return false;
        }
    }

    /**
     *  Checks if there are available padding colors based on the selected material
     *  @returns { Boolean } - true if there are available padding colors, false otherwise
    */
    function hasPaddingColors() : boolean {
        if (order) {
            let currentMaterial = baquet?.seats.find(item => item.material.id === order.materialId)?.material;
            if (currentMaterial && currentMaterial.paddingColors && currentMaterial.paddingColors.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    /**
     *  Checks if there are available stitching colors based on the selected material
     *  @returns { Boolean } - true if there are available stitching colors, false otherwise
    */
    function hasStitchingColors() : boolean {
        if (order) {
            let currentMaterial = baquet?.seats.find(item => item.material.id === order?.materialId)?.material;
            if (currentMaterial && currentMaterial.stitchingColors && currentMaterial.stitchingColors.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    /**
     *  Checks if there are available lining colors based on the selected material
     *  @returns { Boolean } - true if there are available lining colors, false otherwise
    */
    function hasLiningColors() : boolean {
        if (order) {
            let currentMaterial = baquet?.seats.find(item => item.material.id === order.materialId)?.material;
            if (currentMaterial && currentMaterial.liningColors && currentMaterial.liningColors.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    /**
     *  Updates the available mounting options based on the already selected mounting options
     *  @returns { void }
    */
    function changeMounts() : void {
        let newAvailableMounts: Array<Mount> = [];
        baquet?.mountingOptions.forEach(option => {
            if (selectedMounts.every(selectedMount => option.mounts.some(optionMount => optionMount.identifier === selectedMount.identifier))){
                option.mounts.forEach(mount => {
                    if (!newAvailableMounts.some(item => item.identifier === mount.identifier)){
                        newAvailableMounts.push(mount);
                    }
                })
            }
        })
        newAvailableMounts.sort((a, b) => a.identifier.localeCompare(b.identifier));
        setAvailableMounts(newAvailableMounts);
    }

    /**
     *  Calculate the multiplication between two possibly undefined numbers
     *  @returns { number | string } - the result of the multiplication or '???' if any of the numbers is undefined
    */
    function multiplyPossiblyUndefined(a: number|undefined, b: number|undefined) : number | string {
        if (a !== undefined && a !== null && b !== undefined && b !== null) {
            return (a * b).toFixed(2);
        }
        else {
            return "???";
        }
    }

    /**
     *  Calculates the shipping price of the order based on the country and number of boxes required
     *  @returns { number } shippingPrice - price of the shipping
    */
    function calculateShippingPrice() : number {
        let shippingPrice = 0;
        if (baquet && baquet.quantityPerBox && order && order.quantity && country && country.pricePerBox) {
            let numberOfRequiredBoxes = 0;
            numberOfRequiredBoxes = numberOfRequiredBoxes + Math.floor(order?.quantity / baquet?.quantityPerBox);
            numberOfRequiredBoxes = numberOfRequiredBoxes + (order?.quantity % baquet?.quantityPerBox);
            shippingPrice = numberOfRequiredBoxes * country.pricePerBox;
        }
        return shippingPrice;
    }

    /**
     *  Validates if all the required fields are filled correctly
     *  @returns { boolean } - true if all the fields are filled correctly, false otherwise
    */
    function validateFields() : boolean {
        if (order) {
            if (baquet?.finishings.some(finishing => finishing.id === order.finishingId && (finishing.name === 'Raptor Coating' || finishing.name === 'Other RAL Colors')) && !order.finishingRALcode) {
                setPopUpTitle('Missing Information');
                setPopMessage('Please fill in the intended RAL code for the selected finishing.');
                setPopUpIsOpen(true);
                return false;
            }
            if (baquet?.finishings.some(finishing => finishing.id === order.finishingId && (finishing.name === 'Raptor Coating' || finishing.name === 'Other RAL Colors')) && order.finishingRALcode?.length !== 4) {
                setPopUpTitle('Invalid Information');
                setPopMessage('Please insert a valid RAL code for the selected finishing. It must have 4 digits.');
                setPopUpIsOpen(true);
                return false;
            }
            if (!order.customer.name || Validator.isStringNullOrEmpty(order.customer.name)) {
                setPopUpTitle('Missing Information');
                setPopMessage('Please fill in your name in a valid format.');
                setPopUpIsOpen(true);
                return false;
            }
            if (!order.customer.address || Validator.isStringNullOrEmpty(order.customer.address)) {
                setPopUpTitle('Missing Information');
                setPopMessage('Please fill in your address in a valid format.');
                setPopUpIsOpen(true);
                return false;
            }
            if (!order.customer.email || !Validator.isValidEmail(order.customer.email)) {
                setPopUpTitle('Missing Information');
                setPopMessage('Please fill in your email in a valid format.');
                setPopUpIsOpen(true);
                return false;
            }
            if (!order.customer.phone || Validator.isStringNullOrEmpty(order.customer.phone)) {
                setPopUpTitle('Missing Information');
                setPopMessage('Please fill in your phone number in a valid format.');
                setPopUpIsOpen(true);
                return false;
            }
            // if (order.customer.isCompany && (order.customer.vatNumber && Validator.isNotANumber(order.customer.vatNumber))) {
            //     setPopUpTitle('Missing Information');
            //     setPopMessage('Please fill in your NIF in a valid format.');
            //     setPopUpIsOpen(true);
            //     return false;
            // }
            var checkBox = document.getElementById("certification-checkbox") as HTMLInputElement;
            if (!checkBox || !checkBox.checked){
                setPopUpTitle('Missing Confirmation');
                setPopMessage('Please confirm the certification checkbox.');
                setPopUpIsOpen(true);
                return false
            }
            return true;
        }
        else {
            return false;
        }
    }

    /**
     *  Validates the fields from the details section and sends requests to validate the recaptcha and to place the order
     *  @returns { void }
    */
    function placeOrder() : void {
        //console.log(order);
        if (validateFields()){
            recaptchaService.validateRecaptcha(token)
            .then((res) => {
                if (order) {
                    orderService.placeOrder(order)
                    .then((res => {
                        setPopUpIsOpen(true);
                        setPopSuccess(true);
                        setPopUpTitle("Your order has been placed!");
                        setPopMessage("You will receive an email to complete your order.\nThank you for choosing RCC.");
                    }))
                }
            })
            .catch((error) => {
                setPopUpIsOpen(true);
                setPopSuccess(false);
                setPopUpTitle("Error")
                setPopMessage(error.message);
            });
        }
    }

    /**
     *  Handles the closing of the popup
     *  @returns { void }
    */
    const handlePopUpClose = () : void => {
        setPopUpIsOpen(false);
    };

    return (
        <DefaultLayout 
            navBarProps={{links: [{name: "About", link: "/about"}, {name: "Projects", link: "/portfolio"}, {name: "Contacts", link: "/contacts"}]}}
            showFooter={true}>

            {
                !showError && (

                    <div className="App seat">

                        { showCustomerForm && (

                            <div className="seat__price">
                                <p className="seat__price_text">
                                    {price + currency}
                                </p>
                            </div>
                        )}

                        <div className="seat__image">
                            <img src={baquet?.imageURL} alt="baquet-design"/>
                        </div>

                        <div className="seat__options">

                            <span className="title-text">
                                SEAT OPTIONS
                            </span>

                            <div className="options__section">

                                <span className="subtitle-text">
                                    QUANTITY
                                </span>

                                <input className="input" type="text" placeholder="1" defaultValue={"1"} maxLength={3}
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={(event) => {
                                        if (event.target.value) {
                                            if (order) {
                                                const updatedOrder = { ...order, quantity: parseInt(event.target.value)};
                                                setOrder(updatedOrder);
                                            }
                                        }
                                        if (event.target.value.trim() === '') {
                                            if (order) {
                                                const updatedOrder = { ...order, quantity: 1};
                                                setOrder(updatedOrder);
                                            }
                                        }
                                    }}
                                />

                            </div>

                            <div className="options__section">

                                <div className="info__title">
                                    <span className="subtitle-text">
                                        SIZE
                                    </span>
                                    <InfoOverlay>
                                        <div className="only-text__overlay">
                                            <span className='info-container-text'>Aproximate user height and weight for suitable size selection:</span>
                                            <span className='info-container-text'>Standard up to 1.80m / 75kg</span>
                                            <span className='info-container-text'>XL up to 1.95m / 100kg</span>
                                            <span className='info-container-text'>XXL up to 2.10m / 120kg</span>
                                        </div>
                                    </InfoOverlay>
                                </div>

                                <div className="input-options">
                                    {
                                        baquet?.sizes.map((size, index) => {
                                            return <input key={index} className={`button${order?.sizeId === size.id ? '--selected' : ''}`} type="button" value={size.abbreviation === 'STD' ? 'Standard' : size.abbreviation} 
                                                    onClick={() => {
                                                        if (order) {
                                                            const updatedOrder = { ...order, sizeId: size.id };
                                                            setOrder(updatedOrder);
                                                        }
                                                    }}/>
                                        })
                                    }
                                </div>

                            </div>

                            <div className="options__section">

                                <div className="info__title">
                                    <span className="subtitle-text">
                                        FINISHING
                                    </span>
                                    <InfoOverlay>
                                        <div className="finishing__overlay">
                                            {
                                                baquet?.finishings.map((finishing, index) => {
                                                    return  <div key={index} className="finishing__overlay-option">
                                                                <img src={finishing.imageURL} alt={"finishing-"+finishing.name}/>
                                                                <p>{finishing.name}</p>
                                                            </div>
                                                })
                                            }
                                        </div>
                                    </InfoOverlay>
                                </div>
                            
                                <div className="input-options">
                                    {
                                        baquet?.finishings.map((finishing, index) => {
                                            return  <input key={index} className={`button${order?.finishingId === finishing.id ? '--selected' : ''}`} type="button" value={finishing.name}
                                                        onClick={() => {
                                                            if (order) {
                                                                const updatedOrder = { ...order, finishingId: finishing.id};
                                                                setOrder(updatedOrder);
                                                            }
                                                        }}
                                                    />
                                        })
                                    }
                                </div>

                                {
                                    baquet?.finishings.some(finishing => finishing.id === order?.finishingId && finishing.name === 'Raptor Coating') &&

                                    <div className="input-options-ral">
                                        <input type="text" className='input hidden-input' value={order?.finishingRALcode ? order.finishingRALcode : ''} placeholder="RAPTOR RAL CODE" maxLength={4}
                                            onChange={(event) => {
                                                if (event.target.value && /^\d*$/.test(event.target.value)) {
                                                    if (order) {
                                                        const updatedOrder = { ...order, finishingRALcode: event.target.value};
                                                        setOrder(updatedOrder);
                                                    }
                                                }
                                                else {
                                                    if (order) {
                                                        const updatedOrder = { ...order, finishingRALcode: null};
                                                        setOrder(updatedOrder);
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                
                                {
                                    baquet?.finishings.some(finishing => finishing.id === order?.finishingId && finishing.name === 'Other RAL Colors') &&

                                    <div className="input-options-ral">
                                        <input type="text" className='input' value={order?.finishingRALcode ? order.finishingRALcode : ''} placeholder="GLOSS RAL CODE" maxLength={4}
                                            onChange={(event) => {
                                                if (event.target.value && /^\d*$/.test(event.target.value)) {
                                                    if (order) {
                                                        const updatedOrder = { ...order, finishingRALcode: event.target.value};
                                                        setOrder(updatedOrder);
                                                    }
                                                }
                                                else {
                                                    if (order) {
                                                        const updatedOrder = { ...order, finishingRALcode: null};
                                                        setOrder(updatedOrder);
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                }

                            </div>

                            { availableExtras.length > 0 && (

                                <div className="options__section">

                                    <span className="subtitle-text">
                                        EXTRAS
                                    </span>

                                    <div className="extras__list">
                                        {
                                            baquet?.extras.filter(item => availableExtras.includes(item.id)).map((extra, index) => { 
                                                return (
                                                    <div className="checkbox-container" key={index}>
                                                        <div className="extras-checkbox">
                                                            <input type="checkbox" className="checkbox-round" id={"extra-check-" + extra.id}
                                                                onClick={() => {
                                                                    updateExtras(extra.id);
                                                                }}/>
                                                            <span>{extra.name}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            )}

                        </div>

                        <div className="padding__options">

                            <span className="title-text">
                                PADDING OPTIONS
                            </span>

                            <div className='padding__options-section'>

                                <span className="subtitle-text">
                                    MATERIAL AND DESIGN
                                </span>

                                <div className="input-options">
                                    {
                                        baquet?.seats.map((seat, index) => {
                                            if (availableMaterials.includes(seat.material.id)) {
                                                return <input key={index} className={`button${order?.materialId === seat.material.id ? '--selected' : ''}`} type="button" value={seat.material.name} 
                                                            onClick={() => {
                                                                if (order) {
                                                                    let updatedOrder = updateSelectedColors(seat.material.id);
                                                                    if (updatedOrder) {
                                                                        updatedOrder.materialId = seat.material.id;
                                                                    }
                                                                    else {
                                                                        updatedOrder = { ...order, materialId: seat.material.id};
                                                                    }
                                                                    setOrder(updatedOrder);
                                                                }
                                                            }}/>
                                            }
                                            else {
                                                return <div key={index}/>
                                            }
                                        })
                                    }
                                </div>
                                
                                <div className="design__options">
                                    {
                                        baquet?.seats.find(item => item.material.id === order?.materialId)?.paddings.map((padding, index) => {
                                            return  <div key={index} className="design__option">
                                                        <img className={`design__image`} src={order?.paddingId === padding.id ? padding.imageOutlineURL : padding.imageURL} alt={"padding-"+padding.identifier}
                                                            onClick={() => {
                                                                if (order) {
                                                                    const updatedOrder = { ...order, paddingId: padding.id};
                                                                    setOrder(updatedOrder);
                                                                }
                                                            }}/>
                                                        <p key={index} className="design__label">
                                                            {padding.splice}
                                                        </p>
                                                    </div>
                                        })
                                    }
                                </div>

                            </div>

                            <div className="padding__options-section">

                                { order && areHeadrestsAvailable(baquet, order?.materialId) &&  (

                                    <div className="padding__headrest">

                                        <div className="info__title">
                                            <span className="subtitle-text">
                                                HEADREST
                                            </span>
                                            <InfoOverlay>
                                                <div className="only-text__overlay">
                                                    <span className='info-container-text'>When selecting an headrest with a custom logo, please select an image file that ilustrates your prefered design.</span>
                                                </div>
                                            </InfoOverlay>
                                        </div>

                                        <div className="headrest__options">
                                            {
                                                baquet?.seats.find(item => item.material.id === order.materialId)?.headrests.map((headrest, index) => {
                                                    return  <div key={index} className="headrest__option">
                                                                <img src={order?.headrestId === headrest.id ? headrest.imageOutlineURL : headrest.imageURL} alt={"headrest"+headrest.identifier} 
                                                                            onClick={() => {
                                                                                if (order) {
                                                                                    const updatedOrder = { ...order, headrestId: headrest.id};
                                                                                    setOrder(updatedOrder);
                                                                                    if (headrest.name.includes('Custom Logo')) {
                                                                                        setShowHeadrestImageUpload(true);
                                                                                    }
                                                                                    else {
                                                                                        setShowHeadrestImageUpload(false);
                                                                                    }
                                                                                }
                                                                            }}/>
                                                                <span className="headrest__label">
                                                                    {headrest.name}
                                                                </span>
                                                            </div>
                                                })
                                            }
                                        </div>

                                        { showHeadrestImageUpload && (
                                        
                                            <div className="headrest__upload-file">
                                                <span>Please choose a PNG, JPG, JPEG or PDF file with the intended image to embroid on the headrest.</span>
                                                <input 
                                                    type="file" 
                                                    id="file-input" 
                                                    accept=".png, .jpg, .jpeg, .pdf" 
                                                    onChange={(event) => {
                                                        if (event.target.files && event.target.files[0] && order) {
                                                            const file = event.target.files[0];
                                                            if (file.size > maxFileSize) {
                                                                setPopUpTitle('File too big');
                                                                setPopMessage('The selected file is too big. Please select a file with a maximum size of 10MB.');
                                                                setPopUpIsOpen(true);
                                                                event.target.value = '';
                                                            }
                                                            else {
                                                                toBase64(file)
                                                                .then((convertedFile: string) => {
                                                                    const updatedOrder = { ...order, headrestImage: convertedFile};
                                                                    setOrder(updatedOrder);
                                                                })
                                                                .catch((error) => {
                                                                    if (error instanceof CustomError) {
                                                                        const props: ErrorPageProps = getErrorInformation(error);
                                                                        setErrorProps(props);
                                                                        setShowError(true);
                                                                    }
                                                                    else {
                                                                        const props: ErrorPageProps = getErrorInformation(new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, "Unexpected behaviour reading the headrest file.", undefined));
                                                                        setErrorProps(props);
                                                                        setShowError(true);
                                                                    }
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>

                                        )}

                                    </div>
                                )}

                            </div>

                            <div className="padding__options-section">

                                { (hasPaddingColors() || hasStitchingColors() || hasLiningColors()) && (
                                
                                    <div className="seat__colors">

                                        <span className="subtitle-text">
                                            COLORS
                                        </span>

                                        { hasPaddingColors() && (

                                            <div className="color__section">

                                                <span className="subsubtitle-text">
                                                    PADDING COLOR
                                                </span>

                                                <div className="color__options">

                                                    {
                                                        baquet?.seats.find(item => item.material.id === order?.materialId)?.material?.paddingColors.map((color, index) => {
                                                            return <div key={index} className={order?.paddingColorTag === color.tag ? 'color__option-selected' : 'color__option'}>
                                                                    <img src={color.imageURL} alt={"color-"+color.tag}
                                                                        onClick={() => {
                                                                            if (order) {
                                                                                const updatedOrder = { ...order, paddingColorTag: color.tag};
                                                                                setOrder(updatedOrder);
                                                                            }
                                                                        }}/>
                                                                </div>
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        )}

                                        { hasStitchingColors() && (

                                            <div className="color__section">

                                                <span className="subsubtitle-text">
                                                    STITCHING COLOR
                                                </span>
                                                
                                                <div className="color__picker">

                                                    {
                                                        sortStitchingColors().map((item, index) => {
                                                            return  <div key={index} className={order?.stitchingColorTag === item.catalogueCode ? 'color-option color-option--selected' : 'color-option'} style={{backgroundColor: '#' + item.hexCode}}
                                                                        onClick={() => {
                                                                            if (order) {
                                                                                const updatedOrder = { ...order, stitchingColorTag: item.catalogueCode};
                                                                                setOrder(updatedOrder);
                                                                            }
                                                                        }}
                                                                        >
                                                                    </div>
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        )}

                                        { hasLiningColors() && (

                                            <div className="color__section">

                                                <span className="subsubtitle-text">
                                                    LINING COLOR
                                                </span>

                                                <div className="color__options">

                                                    {
                                                        baquet?.seats.find(item => item.material.id === order?.materialId)?.material?.liningColors.map((color, index) => {
                                                            return <div key={index} className={order?.liningColorTag === color.tag ? 'color__option-selected' : 'color__option'}>
                                                                    <img src={color.imageURL} alt={"color-"+color.tag}
                                                                        onClick={() => {
                                                                            if (order) {
                                                                                const updatedOrder = { ...order, liningColorTag: color.tag};
                                                                                setOrder(updatedOrder);
                                                                            }
                                                                        }}/>
                                                                </div>
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        )}

                                    </div>
                                )}

                            </div>

                        </div>

                        <div className="mounting_options">

                            {/* <span className="title-text">
                                MOUNTING OPTIONS
                            </span> */}

                            <div className="info__title">
                                <span className="title-text">
                                    MOUNTING OPTIONS
                                </span>
                                <InfoOverlay>
                                    <div className="finishing__overlay">
                                        <div className="only-text">
                                            <span className="regular-text">
                                                You can select more than one mount for your seat. As you select the mounts, the available options for the possible combinations will be updated.
                                            </span>
                                        </div>
                                    </div>
                                </InfoOverlay>
                            </div>

                            <div className="mount__options">

                                {
                                    availableMounts.map((mount, index) => {
                                        return <div key={index} className={`mount__design${selectedMounts.some(item => item.identifier === mount.identifier) ? '--selected' : ''}`} onClick={() => {
                                            // check if mount is already selected
                                            if (selectedMounts.some(item => item.identifier === mount.identifier)) {
                                                // remove the mount from the selected mounts
                                                let newSelectedMounts = selectedMounts.filter(item => item.identifier !== mount.identifier);
                                                setSelectedMounts(newSelectedMounts);
                                            }
                                            else {
                                                // add the mount to the selected mounts
                                                const newSelectedMounts = [...selectedMounts, mount];
                                                setSelectedMounts(newSelectedMounts);
                                            }
                                            if (baquet) {
                                                changeMounts();
                                            }
                                        }}>
                                            <img className='mount__image' src={mount.imageURL} alt={"mount-"+mount.identifier}/>
                                            <p className="mount__label">
                                                {mount.name}
                                            </p>
                                        </div>
                                    })
                                }

                            </div>

                        </div>

                        { showCustomerForm && (

                            <div className="details">

                                <span className="title-text">
                                    DETAILS
                                </span>

                                <div className="input-options">
                                    <input className={`button${order?.customer.isCompany ? '' : '--selected'}`} type="button" value={'Private Buyer'} 
                                        onClick={() => {
                                            if (order) {
                                                const updatedCustomer = { ...order.customer, isCompany: false};
                                                const updatedOrder = { ...order, customer: updatedCustomer};
                                                setOrder(updatedOrder);
                                            }
                                        }}
                                    />
                                    <input className={`button${order?.customer.isCompany ? '--selected' : ''}`} type="button" value={'Company'} 
                                        onClick={() => {
                                            if (order) {
                                                const updatedCustomer = { ...order.customer, isCompany: true};
                                                const updatedOrder = { ...order, customer: updatedCustomer};
                                                setOrder(updatedOrder);
                                            }
                                        }}
                                    />
                                </div>

                                <div className="price-list">

                                    <table className="options-table">
                                        <tbody>

                                            <tr>
                                                <td className="regular-text">{order?.quantity + "x " + baquet?.name}</td>
                                                <td className="regular-text">{multiplyPossiblyUndefined(order?.quantity, baquet?.basePrice) + " " + currency}</td>
                                            </tr>

                                        </tbody>
                                    </table>

                                    <table className="options-table">
                                        <tbody>

                                            <tr> 
                                                <td className="regular-text subcategory-item">{"Size: " + baquet?.sizes.find(size => size.id === order?.sizeId)?.abbreviation}</td>
                                                <td className="regular-text">{multiplyPossiblyUndefined(order?.quantity, baquet?.sizes.find(size => size.id === order?.sizeId)?.price) + " " + currency}</td>
                                            </tr>

                                            <tr>   
                                                <td className="regular-text subcategory-item">{"Finishing: " + baquet?.finishings.find(finishing => finishing.id === order?.finishingId)?.name}</td>
                                                <td className="regular-text">{multiplyPossiblyUndefined(order?.quantity, baquet?.finishings.find(finishing => finishing.id === order?.finishingId)?.price) + " " + currency}</td>
                                            </tr>

                                            { order?.extrasIds && order?.extrasIds.length > 0 && (
                                                <tr>
                                                    <td className="regular-text subcategory-item">{"Extras:"}</td>
                                                    <td></td>
                                                </tr>
                                            )}

                                            {
                                                order?.extrasIds.map((extraId, index) => {
                                                    let extra = baquet?.extras.find(item => item.id === extraId);
                                                    if (extra) {
                                                        return <tr key={index}>
                                                            <td className="regular-text subcategory-item list-item">{extra.name}</td>
                                                            <td className="regular-text">{multiplyPossiblyUndefined(order?.quantity, extra.price) + " " + currency}</td>
                                                        </tr>
                                                    }
                                                    else {
                                                        return <></>
                                                    }
                                                })
                                            }
                                            
                                        </tbody>
                                    </table>

                                    <table className="padding-and-mounts-table">
                                        <tbody>

                                            {
                                                baquet?.seats.find(item => item.material.id === order?.materialId)?.paddings.find(item => item.id === order?.paddingId) !== undefined && (
                                                    <tr>  
                                                        <td className="regular-text subcategory-item">{"Padding: " + baquet?.seats.find(item => item.material.id === order?.materialId)?.paddings.find(item => item.id === order?.paddingId)?.splice}</td>
                                                        <td className="regular-text">{multiplyPossiblyUndefined(order?.quantity, baquet?.seats.find(item => item.material.id === order?.materialId)?.paddings.find(item => item.id === order?.paddingId)?.price) + " " + currency}</td>
                                                    </tr>
                                                )
                                            }

                                            {
                                                baquet?.seats.find(item => item.material.id === order?.materialId)?.headrests.find(item => item.id === order?.headrestId) !== undefined && (
                                                    <tr>
                                                        <td className="regular-text subcategory-item">{"Headrest: " + baquet?.seats.find(item => item.material.id === order?.materialId)?.headrests.find(item => item.id === order?.headrestId)?.name}</td>
                                                        <td className="regular-text">{multiplyPossiblyUndefined(order?.quantity, baquet?.seats.find(item => item.material.id === order?.materialId)?.headrests.find(item => item.id === order?.headrestId)?.price) + " " + currency}</td>
                                                    </tr>
                                                )
                                            }

                                            { selectedMounts.length > 0 && (
                                                <tr>
                                                    <td className="regular-text subcategory-item">{"Mounts:"}</td>
                                                    <td></td>
                                                </tr>
                                            )}
                                            {
                                                selectedMounts.map((mount, index) => {
                                                    return  <tr key={index}>
                                                                <td className="regular-text subcategory-item list-item">{mount.name}</td>
                                                                <td className="regular-text">{multiplyPossiblyUndefined(order?.quantity, mount.price) + " " + currency}</td>
                                                            </tr>
                                                })
                                            }

                                        </tbody>
                                    </table>

                                    <table className="vat-and-tax-table">
                                        <tbody>
                                            <tr>
                                                <td className="regular-text">Shipping</td>
                                                <td className="regular-text">{calculateShippingPrice().toFixed(2) + " " + currency}</td>
                                            </tr>

                                            <tr>
                                                <td className="regular-text">VAT</td>
                                                <td className="regular-text">{taxPrice.toFixed(2) + " " + currency}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table className="total-price-table">
                                        <tbody>
                                            <tr>
                                                <td className="regular-text">Total</td>
                                                <td className="regular-text">{price + " " + currency}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                        
                                <input className="input field" type="text" placeholder={order?.customer?.isCompany ? "Company Name" : "Name"}
                                    onChange={(event) => {
                                        if (order) {
                                            const updatedCustomer = { ...order.customer, name: event.target.value};
                                            const updatedOrder = { ...order, customer: updatedCustomer};
                                            setOrder(updatedOrder);
                                        }
                                    }}/>

                                <input className="input field" type="text" placeholder="Full Address"
                                    onChange={(event) => {
                                        if (order) {
                                            const updatedCustomer = { ...order.customer, address: event.target.value};
                                            const updatedOrder = { ...order, customer: updatedCustomer};
                                            setOrder(updatedOrder);
                                        }
                                    }}/>

                                <input className="input field" type="text" placeholder="Email"
                                    onChange={(event) => {
                                        if (order) {
                                            const updatedCustomer = { ...order.customer, email: event.target.value};
                                            const updatedOrder = { ...order, customer: updatedCustomer};
                                            setOrder(updatedOrder);
                                        }
                                    }}/>

                                { order && order.customer && order.customer.isCompany && (
                                
                                        <div className="phone_vat field">
                                            <input className="input" type="text" placeholder="Phone for delivery" maxLength={20}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9+\s]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    if (order) {
                                                        const updatedCustomer = { ...order.customer, phone: event.target.value};
                                                        const updatedOrder = { ...order, customer: updatedCustomer};
                                                        setOrder(updatedOrder);
                                                    }
                                                }}/>
                                            <input className="input" type="text" placeholder="VAT Number" maxLength={11}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9\s]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onChange={(event) => {
                                                    if (order) {
                                                        const updatedCustomer = { ...order.customer, vatNumber: event.target.value};
                                                        const updatedOrder = { ...order, customer: updatedCustomer};
                                                        setOrder(updatedOrder);
                                                    }
                                                }}/>
                                        </div>
                                )}

                                { order && order.customer && !order.customer.isCompany && (

                                    <input className="input field" type="text" placeholder="Phone for delivery" maxLength={20}
                                        onKeyPress={(event) => {
                                            if (!/[0-9+\s()]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(event) => {
                                            if (order) {
                                                const updatedCustomer = { ...order.customer, phone: event.target.value};
                                                const updatedOrder = { ...order, customer: updatedCustomer};
                                                setOrder(updatedOrder);
                                            }
                                        }}
                                    />
                                )}

                                <textarea className="textarea field" placeholder="Is there any additional information you would like to add to your order?"
                                    onChange={(event) => {
                                        if (event.target.value && order) {
                                            const updatedOrder = { ...order, observations: event.target.value};
                                            setOrder(updatedOrder);
                                        }
                                    }}>
                                </textarea>

                                <div className="checkbox-container">
                                    <div className="certification-checkbox">
                                        <input type="checkbox" className="checkbox-round" id="certification-checkbox"
                                            onClick={() => {
                                            }}
                                        />
                                        <span>I understand that this bucket seat has no road or race certification.</span>
                                    </div>
                                </div>

                                <div className="details__recaptcha">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                                        onChange={(value: any) => {
                                            if (value) {
                                                let token = value as string;
                                                setToken(token);
                                            }
                                            else {
                                                setToken('token-expired');
                                            }
                                        }}
                                    />
                                </div>

                                <div className="details__button">
                                    <input className="button--alt" type="button" value="PLACE ORDER" onClick={() => {placeOrder()}}/>
                                </div>


                            </div>
                        )}

                        { !showCustomerForm && (

                            <div className="details">

                                <span className="title-text">
                                    DETAILS
                                </span>

                                <div className="selection-summary">

                                    <span className="regular-text">{baquet?.name}</span>

                                    <span className="regular-text subcategory-item section-first-item">{"Size: " + baquet?.sizes.find(size => size.id === order?.sizeId)?.abbreviation}</span>
                                    <span className="regular-text subcategory-item">{"Finishing: " + baquet?.finishings.find(finishing => finishing.id === order?.finishingId)?.name}</span>
                                    {
                                        order?.extrasIds && order?.extrasIds.length > 0 && (
                                            <span className="regular-text subcategory-item">{"Extras:"}</span>
                                        )
                                    }
                                    {
                                        order?.extrasIds.map((extraId, index) => {
                                            let extra = baquet?.extras.find(item => item.id === extraId);
                                            if (extra) {
                                                return <span key={extra.id} className="regular-text list-item">{extra.name}</span>
                                            }
                                            else {
                                                return <></>
                                            }
                                        })
                                    }

                                    <span className="regular-text subcategory-item section-first-item">{"Padding: " + baquet?.seats.find(item => item.material.id === order?.materialId)?.paddings.find(item => item.id === order?.paddingId)?.splice}</span>

                                    {
                                        baquet?.seats.find(item => item.material.id === order?.materialId)?.headrests.find(item => item.id === order?.headrestId) !== undefined &&
                                        
                                        <span className="regular-text subcategory-item">{"Headrest: " + baquet?.seats.find(item => item.material.id === order?.materialId)?.headrests.find(item => item.id === order?.headrestId)?.name}</span>
                                    }
                                    
                                    {
                                        selectedMounts.length > 0 && (
                                            <span className="regular-text subcategory-item section-first-item">{"Mounts:"}</span>
                                        )
                                    }
                                    {
                                        selectedMounts.map((mount, index) => {
                                            return <span key={index} className="regular-text list-item">{mount.name}</span>  
                                        })
                                    }

                                </div>

                                <div className="dealer__button">
                                    <a href={"mailto:"+country?.dealer?.email}>
                                        <input className="button--alt" type="button" value="CONTACT DEALER"/>
                                    </a>
                                </div>

                            </div>
                        )}

                        <PopUp 
                            isOpen={popUpIsOpen} 
                            isSuccess={popUpSuccess}
                            title={popUpTitle} 
                            message={popUpMessage}
                            buttonMessage="OK"
                            onClose={handlePopUpClose}
                        />

                    </div>
                )
            }

            {
                showError && (
                    
                    <ErrorPage title={errorProps.title} message={errorProps.message}/>
                )
            }

        </DefaultLayout>
    );
}

export default Seat;