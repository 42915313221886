import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import './App.scss';
import { CountryGuardedRoute } from './helpers/guards';
import { CountryProvider, InversifyProvider, LoadingProvider } from './helpers/providers';
import About from './views/about/about';
import Contacts from './views/contacts/contacts';
import NotFoundErrorPage from './views/error/not_found/error';
import Home from './views/home/home';
import Loading from './views/loading/Loading';
import Portfolio from './views/portfolio/portfolio';
import Privacy from './views/privacy/privacy';
import Seat from './views/shop/seat/seat';
import SeatsList from './views/shop/seatsList/seatsList';

const App = () => {
    return(
        <LoadingProvider>
            <Loading/> 
            <InversifyProvider>
                <CountryProvider>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route element={ <CountryGuardedRoute/> }>
                            <Route path="seat" element={<SeatsList />} />
                            <Route path="seat/:id" element={<Seat />} />
                        </Route>
                        <Route path="/about" element={<About />} />
                        <Route path="/portfolio" element={<Portfolio />} />
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="*" element={<NotFoundErrorPage />} />
                    </Routes>
                </CountryProvider>
            </InversifyProvider>
        </LoadingProvider>
    )
}

export default App;
