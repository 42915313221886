import { useLoading } from "../../helpers/contexts";
import './Loading.scss';

const Loading = () => {
    const { isLoading } = useLoading();
    
    return (
        isLoading ? 
            <div className="loading-screen">
                <div className="spinner"></div>
                <p>Loading...</p>
            </div>
            : null
    );
};

export default Loading;
