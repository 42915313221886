import { Validator } from "../../helpers/utils";

export class Extra {
    id: number;
    name: string;
    price: number;

    constructor(id: number, name: string, price: number) {
        this.validateInputs(id, name, price);

        this.id = id;
        this.name = name;
        this.price = price;
    }

    private validateInputs(id: number, name: string, price: number): void {
        if (Validator.isNotANumber(id) || id <= 0) {
            throw new Error(`${this.constructor.name}: ID must be a positive integer.`);
        }
        if (Validator.isStringNullOrEmpty(name)) {
            throw new Error(`${this.constructor.name}: Name must be a non-empty string.`);
        }
        if (Validator.isNotANumber(price) || price <= 0) {
            throw new Error(`${this.constructor.name}: Price must be a positive number.`);
        }
    }

    static fromJson({ json }: { json: any; }): Extra {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const id = json?.id;
        const price = json?.price;
        const name = json?.extra?.data?.attributes?.name;

        if (typeof id !== 'number' || typeof name !== 'string' || typeof price !== 'number') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new Extra(id, name, price);
    }
}