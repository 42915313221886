import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import Gallery from "../../components/gallery";
import { useLoading } from "../../helpers/contexts";
import { getErrorInformation } from "../../helpers/handlers/GlobalErrorHandler";
import { DefaultLayout } from "../../layouts";
import { PortfolioItem } from "../../models/services/PortfolioItem";
import { PortfolioService } from "../../services";
import "./portfolio.scss";
import { ErrorPageProps } from "../../models/props/ErrorPageProps";
import ErrorPage from "../error/generic/error";

const Portfolio = () => {

    // contexts
    const { showLoading, hideLoading } = useLoading();

    // services
    const portfolioService = useInjection(PortfolioService);

    // states
    const [portfolio, setPortfolio] = useState<PortfolioItem[]>([]);

    // error
    const [showError, setShowError] = useState<boolean>(false);
    const [errorProps, setErrorProps] = useState<ErrorPageProps>({title: "Ups!", message: "Something went wrong. Please try again later."});
    
    useEffect(() => {
        showLoading();
        portfolioService.getPorfolioItems()
        .then(response => {
            setPortfolio(response);
        })
        .catch(error => {
            const props: ErrorPageProps = getErrorInformation(error);
            setErrorProps(props);
            setShowError(true);
        })
        .finally(() => hideLoading());
        // eslint-disable-next-line
    }, [portfolioService]);

    return (
        <DefaultLayout 
            navBarProps={{links: [{name: "About", link: "/about"}, {name: "Projects", link: "/portfolio"}, {name: "Contacts", link: "/contacts"}]}}
            showFooter={true}>

            {
                !showError && (

                    <div className="portfolio">
                        {
                            portfolio !== null && portfolio !== undefined && portfolio.length > 0 && (

                                portfolio.map((item: any, index: any) => (

                                    <div className="project" key={index}>
                                        
                                        <div className="section-title project__title">
                                            {item.name}
                                        </div>
                                        
                                        <div className="regular-text project__description">
                                            {item.description}
                                        </div>

                                        <Gallery imagesUrls={item.imagesUrls}></Gallery>

                                    </div>
                                ))
                            )
                        }
                    </div>
                )
            }

            {
                showError && (
                    
                    <ErrorPage title={errorProps.title} message={errorProps.message}/>
                )
            }

        </DefaultLayout>
    );
}

export default Portfolio;
