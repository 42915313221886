import { Validator } from "../../helpers/utils";

export class Incompatibilities {
    id: number
    sizes: number[];
    extras: number[];

    constructor(id: number, sizes: number[], extras: number[]) {
        this.validateInputs(id, sizes, extras);

        this.id = id;
        this.sizes = sizes;
        this.extras = extras;
    }

    private validateInputs(id: number, sizes: number[], extras: number[]): void {
        if (Validator.isNotANumber(id) || id <= 0) {
            throw new Error(`${this.constructor.name}: ID must be a positive integer.`);
        }
        if (!Array.isArray(sizes) || sizes.length < 0) {
            throw new Error(`${this.constructor.name}: sizes must be an array of number objects.`);
        }
        if (!Array.isArray(extras) || extras.length < 0) {
            throw new Error(`${this.constructor.name}: extras must be an array of number objects.`);
        }
    }

    static fromJson({ json }: { json: any }): Incompatibilities {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const id = json?.id;
        const sizeIncompatibilitiesData = json?.sizes?.data;
        const extrasIncompatibilitiesData = json?.extras?.data;

        if (typeof id !== 'number' || typeof sizeIncompatibilitiesData !== 'object' || typeof extrasIncompatibilitiesData !== 'object') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }
        
        const sizeIncompatibilities: number[] = sizeIncompatibilitiesData ? Object.values(sizeIncompatibilitiesData as Object).map(val => val?.id) : [];
        const extrasIncompatibilities: number[] = extrasIncompatibilitiesData ? Object.values(extrasIncompatibilitiesData as Object).map(val => val?.id) : [];
        
        return new Incompatibilities(id, sizeIncompatibilities, extrasIncompatibilities);
    }
}