import { ReactNode } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { NavBarProps } from "../../models/props/NavBarProps";
import { NavBarOption } from "../../models/types/NavBarComponent.types";
import "./navBar.scss";


const NavBar = (props: NavBarProps) => {

    const navOptions: ReactNode = props.links.map((option: NavBarOption) => {return<Nav.Link key={option.name} href={option.link}>{option.name}</Nav.Link>})

    return (
        <Navbar collapseOnSelect className= "navbar" expand="md" fixed="top">

            <a href="/">
                <img src="../../lusomotors.png" alt="lusomotors-logo"/>
            </a>
            
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end navbar-container__menu">
                <Nav>{ navOptions }</Nav>
            </Navbar.Collapse>

        </Navbar>
    );
}

export default NavBar;