function extractNumber(str: string): number | null {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
}

export function customComparator(a: string, b: string): number {
    const numA = extractNumber(a);
    const numB = extractNumber(b);
  
    // If both strings have numeric values, compare them
    if (numA !== null && numB !== null) {
      return numA - numB;
    }
  
    // If one of the strings doesn't have a numeric value, compare them as strings
    return a.localeCompare(b);
}