import { DataMapper, Validator } from "../../helpers/utils";
import { Extra } from "./Extra";
import { Finishing } from "./Finishing";
import { MountingOption } from "./MountingOption";
import { Seat } from "./Seat";
import { Size } from "./Size";

export class Baquet {
    name: string;
    imageURL: string;
    basePrice: number;
    quantityPerBox: number;
    sizes: Size[];
    finishings: Finishing[];
    extras: Extra[];
    seats: Seat[];
    mountingOptions: MountingOption[];
    
    constructor(name: string, imageURL: string, basePrice: number, quantityPerBox: number, sizes: Size[], finishings: Finishing[], extras: Extra[], seats: Seat[], mountingOptions: MountingOption[]) {
        this.validateInputs(name, imageURL, basePrice, quantityPerBox, sizes, finishings, extras, seats, mountingOptions);
        
        this.name = name;
        this.imageURL = imageURL;
        this.basePrice = basePrice;
        this.quantityPerBox = quantityPerBox;
        this.sizes = sizes;
        this.finishings = finishings;
        this.extras = extras;
        this.seats = seats;
        this.mountingOptions = mountingOptions;
    }

    private validateInputs(name: string, imageURL: string, basePrice: number, quantityPerBox: number, sizes: Size[], finishings: Finishing[], extras: Extra[], seats: Seat[], mountingOptions: MountingOption[]): void {
        if (Validator.isStringNullOrEmpty(name)) {
            throw new Error(`${this.constructor.name}: Name must be a non-empty string.`);
        }
        if (!Validator.isValidUrl(imageURL)) {
            throw new Error(`${this.constructor.name}: Invalid image URL '${imageURL}'.`);
        }
        if (Validator.isNotANumber(basePrice) || basePrice < 0) {
            throw new Error(`${this.constructor.name}: Price must be a positive number.`);
        }
        if (Validator.isNotANumber(quantityPerBox) || quantityPerBox <= 0) {
            throw new Error(`${this.constructor.name}: Quantity per Shipping Box must be a positive number.`);
        }
        if (!Array.isArray(sizes) || sizes.length === 0) {
            throw new Error(`${this.constructor.name}: Sizes must be a non-empty array.`);
        }
        if (!Array.isArray(finishings)) {
            throw new Error(`${this.constructor.name}: Finishings must be an array.`);
        }
        if (!Array.isArray(extras)) {
            throw new Error(`${this.constructor.name}: Extras must be an array.`);
        }
        if (!Array.isArray(seats)) {
            throw new Error(`${this.constructor.name}: Seats must be an array.`);
        }
        if (!Array.isArray(mountingOptions)) {
            throw new Error(`${this.constructor.name}: Mounting options must be an array.`);
        }
    }

    static fromJson({ json }: { json: any }): Baquet {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const name = json?.name;
        const imageUrl = `${process.env.REACT_APP_STRAPI_API_BASE_URL}${json?.image?.data?.attributes?.url}`;
        const basePrice = json?.basePrice;
        const quantityPerBox = json?.shipping?.quantityPerBox;
        const sizes: Size[] = DataMapper.createFromData(json?.sizes?.data, Size.fromJson);
        const finishings: Finishing[] = DataMapper.createFromData(json?.finishings?.data, Finishing.fromJson);
        const extras: Extra[] = DataMapper.createFromData(json?.extras, Extra.fromJson);
        const seats: Seat[] = DataMapper.createFromData(json?.seats, Seat.fromJson);
        const mountingOptions: MountingOption[] = DataMapper.createFromData(json?.mountings, MountingOption.fromJson);

        if (typeof name !== 'string' || typeof imageUrl !== 'string' || typeof basePrice !== 'number' || typeof quantityPerBox !== 'number') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new Baquet(name, imageUrl, basePrice, quantityPerBox, sizes, finishings, extras, seats, mountingOptions);
    }
}