import { Container } from "react-bootstrap";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import "./Footer.scss";

export const Footer = () => {

    return(
        <Container fluid className="footer">
            <div className="footer-content">

                <div className="main-accesses">

                    <div className="first-column">
                        <div className="social-icons">
                            <a href="https://www.instagram.com/lusomotors">
                                <FaInstagram className="icon-image"/>
                            </a>
                            <a href="https://www.facebook.com/LusoMotors">
                                <FaFacebook className="icon-image"/>
                            </a>
                        </div>
                        <a className="regular-text main-contacts" href="mailto:lusomotors@lusomotors.com">{"lusomotors@lusomotors.com"}</a>
                        <span className="regular-text main-contacts">{"+351 960257155"}</span>
                    </div>

                    <div className="second-column">
                        <a className="regular-text" href="/about">{"About"}</a>
                        <a className="regular-text" href="/portfolio">{"Projects"}</a>
                        <a className="regular-text" href="/contacts">{"Contacts"}</a>
                    </div>

                </div>

                <div className="third-column">
                    <a className="regular-text" href="/privacy">{"Privacy Policy"}</a>
                    <span className="regular-text">&copy; Lusomotors, {(new Date().getFullYear())} All rights reserved</span>
                </div>

            </div>
        </Container>
    )
}