export class Customer {
    name: string;
    address: string;
    countryCode: string;
    email: string;
    phone: string;
    vatNumber: string;
    isCompany: boolean;
    
    constructor(name: string, address: string, countryCode: string, email: string, phone: string, vatNumber: string, isCompany: boolean) {
        this.name = name;
        this.address = address;
        this.countryCode = countryCode;
        this.email = email;
        this.phone = phone;
        this.vatNumber = vatNumber;
        this.isCompany = isCompany;
    }
}