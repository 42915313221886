import { useState } from 'react';
import { Carousel, Col, Container, Modal, Row } from 'react-bootstrap';
import { GalleryProps } from '../../models/props/GalleryProps';
import './index.scss';

const Gallery = (props: GalleryProps) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleImageClick = (imageIndex: number) => {
        setSelectedImageIndex(imageIndex);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCarouselSelect = (selectedIndex: number) => {
        if (selectedIndex > selectedImageIndex) {
            // Navigated to the next item (front)
            console.log('Navigated to the next item (front)');
        } else if (selectedIndex < selectedImageIndex) {
            // Navigated to the previous item (back)
            console.log('Navigated to the previous item (back)');
        }
        setSelectedImageIndex(selectedIndex);
    };

    return (
        <Container fluid className="image-grid-container">

            <Row>
                {props.imagesUrls.map((imageUrl, index) => (
                    <Col key={index} className="image-grid-item" xs={6} sm={6} md={4} lg={3} xl={2}>
                        <div className="image-wrapper" onClick={() => handleImageClick(index)}>
                            <img src={imageUrl} alt={`${index}`} className="grid-image"/>
                        </div>
                    </Col>
                ))}
            </Row>

            <Modal 
                show={showModal}
                size="xl"
                onHide={handleCloseModal}>

                <Carousel activeIndex={selectedImageIndex} interval={null} onSelect={handleCarouselSelect} keyboard={true} indicators={false}>
                    {props.imagesUrls.map((imageUrl, index) => (
                        <Carousel.Item key={index}>
                            <div className="carousel-image-container">
                                <img src={imageUrl} alt={`${index}`} className="carousel-image"/>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>

            </Modal>
            
        </Container>
    );
};

export default Gallery;