export default class DataMapper {
    private static createObjects<T>(data: Record<string, any>, creatorFn: (val: any) => T): T[] {
        return Object.values(data).map(val => creatorFn({ json: val }));
    }

    static createFromData<T>(data: Record<string, any>, creatorFn: (val: any) => T): T[] {
        return this.createObjects(data, creatorFn);
    }

    static toFormData(obj: Record<string, any>): FormData {
        const formData = new FormData();

        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                const value = obj[key];
                if (Array.isArray(value) || typeof value === 'object') {
                    formData.append(key, JSON.stringify(value));
                } else if (value !== undefined && value !== null) {
                    formData.append(key, value.toString());
                }
            }
        }
    
        return formData;
    }
}