
import { Modal } from "react-bootstrap";
import { PopUpProps } from "../../models/props/PopUpProps";
import "./popUp.scss";

export const PopUp = (props: PopUpProps) => {

    return (
        <Modal
            className='bug--fix'
            show={props.isOpen}
            animation={false}
            >
        
            <Modal.Header>

                <Modal.Title>{props.title}</Modal.Title>
        
            </Modal.Header>

            <Modal.Body>                 
            
                <div className="modal-body">
                    <span className="regular-text">{props.message}</span>
                </div>

            </Modal.Body>

            <Modal.Footer>

                <button className="button--alt" onClick={() => {props.onClose()}}>
                    {props.buttonMessage}
                </button>

            </Modal.Footer>

        </Modal>
    )
}