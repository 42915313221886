import { useInjection } from 'inversify-react';
import { useEffect, useState } from 'react';
import { useLoading } from '../../helpers/contexts';
import { getErrorInformation } from '../../helpers/handlers/GlobalErrorHandler';
import { DefaultLayout } from "../../layouts";
import { Country } from '../../models/services/Country';
import CountryService from '../../services/CountryService';
import "./contacts.scss";
import { ErrorPageProps } from '../../models/props/ErrorPageProps';
import ErrorPage from '../error/generic/error';

const Contacts = ()  => {

    // contexts
    const { showLoading, hideLoading } = useLoading();
    
    // services
    const countryService = useInjection(CountryService);

    // states
    const [dealerCountries, setDealerCountries] = useState<Country[]>([]);

    // error
    const [showError, setShowError] = useState<boolean>(false);
    const [errorProps, setErrorProps] = useState<ErrorPageProps>({title: "Ups!", message: "Something went wrong. Please try again later."});

    // run when the page first loads
    useEffect(() => {
        showLoading();
        countryService.getDealerCountries().then((response) => {
            setDealerCountries(response);
        })
        .catch((error) => {
            const props: ErrorPageProps = getErrorInformation(error);
            setErrorProps(props);
            setShowError(true);
        })
        .finally(() => {
            hideLoading();
        });
        // eslint-disable-next-line
    }, []);

    return(
        <DefaultLayout 
            navBarProps={{links: [{name: "About", link: "/about"}, {name: "Projects", link: "/portfolio"}, {name: "Contacts", link: "/contacts"}]}}
            showFooter={true}>

            {
                !showError && (

                    <div className="contacts">

                        <div className="dealers">

                            <span className="section-title title">Contact our sales and customer service team, we are more than happy to help!</span>

                            <div className="dealer-option main-dealer">

                                <span className="regular-text country-name">Portugal</span>
                                
                                <a className="regular-text" href={"mailto:lusomotors@lusomotors.com"}>lusomotors@lusomotors.com</a>

                            </div>

                            <span className="section-title title">Or directly contact your area's exclusive dealer</span>

                            {
                                dealerCountries.map((country, index) => {
                                    return (
                                        <div key={index} className="dealer-option">

                                            <span className="regular-text country-name">{country.name}</span>

                                            <div className="info">
                                                <span className="regular-text">{country.dealer?.entity}</span>
                                                <span className="regular-text">{"."}</span>
                                                <a className="regular-text" href={"mailto:" + country.dealer?.email}>{country.dealer?.email}</a>
                                            </div>

                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                )
            }

            {
                showError && (
                    
                    <ErrorPage title={errorProps.title} message={errorProps.message}/>
                )
            }

        </DefaultLayout>
    )
}

export default Contacts;