import { DataMapper } from "../../helpers/utils";
import { Mount } from "./Mount";

export class MountingOption {
    name: string;
    mounts: Mount[];

    constructor(name: string, mounts: Mount[]) {
        this.validateInputs(name, mounts);

        this.name = name;
        this.mounts = mounts;
    }

    private validateInputs(name: string, mounts: Mount[]): void {  
        if (!Array.isArray(mounts) || mounts.length === 0) {
            throw new Error(`${this.constructor.name}: Mounts must be a non-empty array.`);
        }
    }

    static fromJson({ json }: { json: any }): MountingOption {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const name = json?.name;
        const mounts: Mount[] = DataMapper.createFromData(json?.mounts?.data, Mount.fromJson);

        return new MountingOption(name, mounts);
    }
}