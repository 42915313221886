import { Validator } from "../../helpers/utils";

export class Padding {
    id: number;
    identifier: string;
    numPieces: number;
    splice: string;
    price: number;
    imageURL: string;
    imageOutlineURL: string;

    constructor(id: number, identifier: string, numPieces: number, splice: string, price: number, imageURL: string, imageOutlineURL: string) {
        this.validateInputs(id, identifier, numPieces, splice, price, imageURL, imageOutlineURL);
        
        this.id = id;
        this.identifier = identifier;
        this.numPieces = numPieces;
        this.splice = splice;
        this.price = price;
        this.imageURL = imageURL;
        this.imageOutlineURL = imageOutlineURL;
    }

    private validateInputs(id: number, identifier: string, numPieces: number, splice: string, price: number, imageURL: string, imageOutlineURL: string): void {
        if (Validator.isNotANumber(id) || id <= 0) {
            throw new Error(`${this.constructor.name}: ID must be a positive integer.`);
        }
        if (!Validator.isValidRccIdentifier(identifier)) {
            throw new Error(`${this.constructor.name}: Identifier must follow the RCC identifier pattern.`);
        }
        if (Validator.isNotANumber(numPieces) || numPieces < 0) {
            throw new Error(`${this.constructor.name}: Number of pieces must be a non-negative integer.`);
        }
        if (Validator.isStringNullOrEmpty(splice)) {
            throw new Error(`${this.constructor.name}: Splice must not be empty.`);
        }
        if (Validator.isNotANumber(price) || price < 0) {
            throw new Error(`${this.constructor.name}: Price must be a non-negative number.`);
        }
        if (!Validator.isValidUrl(imageURL)) {
            throw new Error(`${this.constructor.name}: Invalid image URL '${imageURL}'.`);
        }
        if (!Validator.isValidUrl(imageOutlineURL)) {
            throw new Error(`${this.constructor.name}: Invalid image outline URL '${imageOutlineURL}'.`);
        }
    }

    static fromJson({ json }: { json: any }): Padding {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const id = json?.id;
        const identifier = json?.attributes?.identifier;
        const numPieces = json?.attributes?.numOfPieces;
        const splice = json?.attributes?.splice;
        const price = json?.attributes?.price;
        const imageURL = `${process.env.REACT_APP_STRAPI_API_BASE_URL}${json?.attributes?.images?.original?.data?.attributes?.url}`;
        const imageOutlineURL = `${process.env.REACT_APP_STRAPI_API_BASE_URL}${json?.attributes?.images?.outlined?.data?.attributes?.url}`;

        if (typeof id !== 'number' || typeof identifier !== 'string' || typeof numPieces !== 'number' || typeof splice !== 'string' || typeof price !== 'number' || typeof imageURL !== 'string' || typeof imageOutlineURL !== 'string') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new Padding(id, identifier, numPieces, splice, price, imageURL, imageOutlineURL);
    }
}