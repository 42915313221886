export class BaquetBasicInfo {
    id: number;
    name: string;
    descritpion: string;
    imageURL: string;

    constructor(id: number, name: string, descritpion: string, imageURL: string) {
        this.id = id;
        this.name = name;
        this.descritpion = descritpion;
        this.imageURL = imageURL;
    }

    static fromJson({ json }: { json: any; }): BaquetBasicInfo {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const id = json?.id;
        const name = json?.attributes?.name;
        const description = json?.attributes?.description;
        const imageURL = `${process.env.REACT_APP_STRAPI_API_BASE_URL}${json?.attributes?.image?.data?.attributes?.url}`;

        if (typeof id !== 'number' || typeof name !== 'string' || typeof description !== 'string' || typeof imageURL !== 'string') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new BaquetBasicInfo(id, name, description, imageURL);
    }
}