import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useLoading } from "../../../helpers/contexts";
import { getErrorInformation } from "../../../helpers/handlers/GlobalErrorHandler";
import { customComparator } from "../../../helpers/utils/StringUtils";
import { DefaultLayout } from "../../../layouts";
import { BaquetBasicInfo } from "../../../models/services/BaquetBasicInfo";
import { BaquetService } from "../../../services";
import "./seatsList.scss";
import { ErrorPageProps } from "../../../models/props/ErrorPageProps";
import ErrorPage from "../../error/generic/error";
        
const SeatsList = () => {

    // contexts
    const { showLoading, hideLoading } = useLoading();
    const navigate = useNavigate();

    // services
    const baquetService: BaquetService = useInjection<BaquetService>(BaquetService);

    // states
    const [baquetsBasicInfoList, setBaquetsBasicInfoList] = useState<Array<BaquetBasicInfo>>([]);

    // error
    const [showError, setShowError] = useState<boolean>(false);
    const [errorProps, setErrorProps] = useState<ErrorPageProps>({title: "Ups!", message: "Something went wrong. Please try again later."});

    useEffect(() => {
        showLoading();
        baquetService.getBaquetsBasicInfo()
        .then(responseBaquetsBasicInfoList => {
            //console.log(responseBaquetsBasicInfoList);
            setBaquetsBasicInfoList(responseBaquetsBasicInfoList.sort((a1, a2) => {
                return customComparator(a1.name, a2.name);
            }));
        })
        .catch(error => {
            const props: ErrorPageProps = getErrorInformation(error);
            setErrorProps(props);
            setShowError(true);
        })
        hideLoading();
        // eslint-disable-next-line
    }, [baquetService]);

    // handle button click
    const navigateToSpecificSeat = (baquetId: number) => {
        navigate(`/seat/${baquetId}`);
    };

    return (
        <DefaultLayout 
            navBarProps={{links: [{name: "About", link: "/about"}, {name: "Projects", link: "/portfolio"}, {name: "Contacts", link: "/contacts"}]}}
            showFooter={true}>

            {
                !showError && (

                    <div className="main-containerzinho container">

                        <div className="seats-list row">

                            {
                                baquetsBasicInfoList.length > 0 && baquetsBasicInfoList.map((baquetBasicInfo, index) => {
                                    return (
                                        <div className="col-xs-12 col-md-6 col-lg-4 list-item" key={index}>

                                            <div className="item-content">
                                                <div className="image-container">
                                                    <img src={baquetBasicInfo.imageURL} alt={"baquet-"+baquetBasicInfo.name}/>
                                                </div>

                                                <p> {baquetBasicInfo.descritpion} </p>
                                            </div>

                                            <button className="button--selected"
                                                onClick={() => {
                                                    navigateToSpecificSeat(baquetBasicInfo.id);  
                                                }}
                                            > 
                                            GET {baquetBasicInfo.name} 
                                            </button>

                                        </div>
                                    )
                                })
                            }

                        </div>

                    </div>
                )
            }

            {
                showError && (
                    
                    <ErrorPage title={errorProps.title} message={errorProps.message}/>
                )
            }

        </DefaultLayout>
    );
}

export default SeatsList;