import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FiInfo } from "react-icons/fi";
import "./infoOverlay.scss";
import { InfoOverlayProps } from '../../models/props/InfoOverlayProps';

export const InfoOverlay = ({children}: InfoOverlayProps) => {

    return(
        <OverlayTrigger placement='top' overlay={
            <Tooltip className='info_tooltip'>
                {children}
            </Tooltip>
        }>  
            <div className="info-container">
                <FiInfo className="info-icon"></FiInfo>
            </div>
        </OverlayTrigger>
    )
}