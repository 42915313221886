import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, HttpStatusCode } from "axios";
import { injectable } from "inversify";
import { ErrorCodes } from "../helpers/enums";
import { CustomError } from "../models/services/CustomError";

/**
 * BaseService class serves as a base class for creating HTTP service instances using Axios.
 * It provides a pre-configured Axios instance with a specified timeout and base URL.
 *
 * @abstract
 * @class BaseService
 */
@injectable()
abstract class BaseService {
    private static readonly MAX_RETRIES: number = 3;
    private static readonly PAGE_SIZE: number = 50;
    protected httpClient: AxiosInstance;

    constructor(timeout: number = 10000) {
        const axiosConfig: AxiosRequestConfig = {
            baseURL: `${process.env["REACT_APP_STRAPI_API_BASE_URL"]}/api`,
            timeout: timeout,
        };

        this.httpClient = axios.create(axiosConfig);

        this.setupRequestInterceptor();

        this.setupResponseInterceptor();
    }

    private setupRequestInterceptor() {
        this.httpClient.interceptors.request.use(
            config => {
                const token = process.env["REACT_APP_STRAPI_API_TOKEN"];

                config.headers.Authorization = `Bearer ${token}`;

                if ((config.headers["Content-Type"] === null && config.data) || config.url?.includes('place-order')) {
                    config.headers["Content-Type"] = 'application/json';
                }

                if (!config.url?.includes('place-order')) {
                    config.url = `${config.url}${ config.url?.includes('?') ? '&' : '?' }pagination[pageSize]=${ BaseService.PAGE_SIZE }`;
                }

                return config;
            }, error => {
                return Promise.reject(error);
            }
        );
    }

    private setupResponseInterceptor() {
        this.httpClient.interceptors.response.use(
            response => response,
            async (error: AxiosError) => {
                if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
                    let retries: number = BaseService.MAX_RETRIES;
                    while (retries > 0) {
                        try {
                            return await this.httpClient.request(error.config as AxiosRequestConfig);
                        } catch {
                            retries--;
                        }
                    }
                }

                if (error.response) {
                    if (error.response.status === HttpStatusCode.NotFound.valueOf()) 
                    {
                        throw new CustomError(ErrorCodes.RESOURCE_NOT_FOUND, 'Resource not found while making request.', error);
                    }
                    else 
                    {
                        throw new CustomError(ErrorCodes.SERVER_ERROR, `Server responded with status ${error.response.status} while making request.`, error);
                    }
                } else if (error.request) {
                    throw new CustomError(ErrorCodes.REQUEST_ERROR, 'No response received while making request.', error);
                } else {
                    throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred while making request: ${error.message}.`, error);
                }
            }
        );
    }
}

export default BaseService;