import { injectable } from "inversify";
import { ErrorCodes } from "../helpers/enums";
import { BaquetOrder } from "../models/services/BaquetOrder";
import { CustomError } from "../models/services/CustomError";
import BaseService from "./BaseService";

/**
 * Service for handling orders.
 */
@injectable()
class OrderService extends BaseService {

    private readonly RESOURCE_PATH : string = "/order";

    /**
     * Places an order.
     * @param order The order to be placed.
     * @throws CustomError if an unexpected error occurs.
     */
    public async placeOrder(order: BaquetOrder): Promise<void> {

        this.httpClient.post(`${this.RESOURCE_PATH}/place-order`, JSON.stringify(order).toString(), {
            headers: {
                'Content-Type': 'text/plain',
            }
        })
        .catch(error => {
            if (error instanceof CustomError) {
                throw error;
            } else {
                throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred: ${error.message}`, error);
            }
        });
    }
}

export default OrderService;