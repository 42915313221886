import { ErrorPageProps } from "../../models/props/ErrorPageProps";
import { CustomError } from "../../models/services/CustomError";
import { ErrorCodes } from "../enums";

export function getErrorInformation(error: Error): ErrorPageProps {
    
    let errorProps: ErrorPageProps = {
        title: "UPS!",
        message: "Something went wrong. Please try again later.",
    }

    if (error instanceof CustomError) {
        switch (error.name) {
            case ErrorCodes.RESOURCE_NOT_FOUND:
                errorProps = {
                    title: "404",
                    message: "Resource not found."
                };
                break;
            case ErrorCodes.REQUEST_ERROR:
                errorProps = {
                    title: "400",
                    message: "Bad request."
                };
                break;
            case ErrorCodes.SERVER_ERROR:
                errorProps = {
                    title: "500",
                    message: "Server error."
                };
                break;
            case ErrorCodes.UNEXPECTED_BEHAVIOUR:
            default:
                break;
        }
    }

    return errorProps ;
}