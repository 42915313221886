import React, { useContext } from "react";
import { Country } from "../../models/services/Country";
import { CountryContextType } from "../../models/types/CountryContext.types";

const CountryContext = React.createContext<CountryContextType>({
    country: null,
    setSelectedCountry: (country: Country) => {}
});

export function useCountry() {
    return useContext(CountryContext);
}

export default CountryContext;