import React, { useEffect } from "react";
import { Country } from "../../models/services/Country";
import { CountryContext } from "../contexts";

const CountryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const CountryStorageKey: string = "S3LECT3D_C0UNTRY";

    const [country, setCountry] = React.useState<Country | null>(() => {
        const storedCountry: any = localStorage.getItem(CountryStorageKey);
        return storedCountry ? JSON.parse(storedCountry) as Country : null;
    });

    function setSelectedCountry(newCountry: Country) {
        setCountry(newCountry);
    }

    useEffect(() => {
        localStorage.setItem(CountryStorageKey, JSON.stringify(country));
    }, [country]);

    return (
        <CountryContext.Provider value={{ country, setSelectedCountry }}>
            {children}
        </CountryContext.Provider>
    )

}

export default CountryProvider;