import { injectable } from "inversify";
import { ErrorCodes } from "../helpers/enums";
import { DataMapper } from "../helpers/utils";
import { Baquet } from "../models/services/Baquet";
import { BaquetBasicInfo } from "../models/services/BaquetBasicInfo";
import { CustomError } from "../models/services/CustomError";
import BaseService from "./BaseService";

/**
 * Service class for retrieving information about Baquets.
 */
@injectable()
class BaquetService extends BaseService {
    private readonly RESOURCE_PATH : string = "/baquets";

    /**
     * Retrieves detailed information about a baquet.
     * @param id The ID of the baquet to retrieve.
     * @returns A promise that resolves to a Baquet object.
     */
    public async getBaquet(id: number): Promise<Baquet> {
        return await this.httpClient.get(`${this.RESOURCE_PATH}/${id}?populate=deep`)
        .then((response) => {
            return response?.data;
        })
        .then((strapiResponse) => {
            let responseDataAttributes = strapiResponse?.data?.attributes;

            if (!responseDataAttributes) {
                throw new CustomError(ErrorCodes.RESOURCE_NOT_FOUND, `Baquet data attributes for ID ${id} were not provided.`);
            }

            return Baquet.fromJson({ json: responseDataAttributes });
        })
        .catch(error => { 
            if (error instanceof CustomError) {
                throw error;
            } else {
                throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred: ${error.message}`, error);
            }
        });
    }

    
    /**
     * Retrieves basic information about baquets.
     * @returns A promise that resolves to an array of BaquetBasicInfo objects.
     */
    public async getBaquetsBasicInfo(): Promise<BaquetBasicInfo[]> {
        return await this.httpClient.get(`${this.RESOURCE_PATH}/?fields[0]=name&fields[1]=description&populate[image][fields]=url`)
        .then((response) => {
            return response?.data;
        })
        .then((strapiResponse) => {
            return DataMapper.createFromData(strapiResponse?.data, BaquetBasicInfo.fromJson);
        })
        .catch(error => {
            if (error instanceof CustomError) {
                throw error;
            } else {
                throw new CustomError(ErrorCodes.UNEXPECTED_BEHAVIOUR, `Unexpected error occurred: ${error.message}`, error);
            }
        });
    }
}

export default BaquetService;