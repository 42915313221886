
import { DataMapper, Validator } from "../../helpers/utils";
import { ColorHex } from "./ColorHex";
import { ColorImage } from "./ColorImage";

export class Material {
    id: number;
    name: string;
    paddingColors: ColorImage[];
    stitchingColors: ColorHex[];
    liningColors: ColorImage[];

    constructor(id: number, name: string, paddingColors: ColorImage[], stitchingColors: ColorHex[], liningColors: ColorImage[]) {
        this.validateInputs(id, name, paddingColors, stitchingColors, liningColors);

        this.id = id;
        this.name = name;
        this.paddingColors = paddingColors;
        this.stitchingColors = stitchingColors;
        this.liningColors = liningColors;
    }

    private validateInputs(id: number, name: string, paddingColors: ColorImage[], stitchingColors: ColorHex[], liningColors: ColorImage[]): void {
        if (Validator.isNotANumber(id) || id <= 0) {
            throw new Error(`${this.constructor.name}: ID must be a positive integer.`);
        }
        if (Validator.isStringNullOrEmpty(name)) {
            throw new Error(`${this.constructor.name}: Name must be a non-empty string.`);
        }
        if (!Array.isArray(paddingColors) || paddingColors.length < 0) {
            throw new Error(`${this.constructor.name}: paddingColors must be an array of Color objects.`);
        }
        if (!Array.isArray(stitchingColors) || stitchingColors.length < 0) {
            throw new Error(`${this.constructor.name}: stitchingColors must be an array of Color objects.`);
        }
        if (!Array.isArray(liningColors) || liningColors.length < 0) {
            throw new Error(`${this.constructor.name}: paddingColors must be an array of Color objects.`);
        }
    }

    static fromJson({ json }: { json: any }): Material {
        if (!json || typeof json !== 'object') {
            throw new Error(`Invalid input provided for ${this.constructor.name}. Expected JSON object, but received ${typeof json}.`);
        }

        const id = json?.material?.data?.id;
        const name = json?.material?.data?.attributes?.name;
        const paddingColors: ColorImage[] = DataMapper.createFromData(json?.colors?.padding?.data, ColorImage.fromJson);
        const stitchingColors: ColorHex[] = DataMapper.createFromData(json?.colors?.stitching?.data, ColorHex.fromJson);
        const liningColors: ColorImage[] = DataMapper.createFromData(json?.colors?.lining?.data, ColorImage.fromJson);

        if (typeof id !== 'number' || typeof name !== 'string') {
            throw new Error(`Invalid JSON format for ${this.constructor.name} object.`);
        }

        return new Material(id, name, paddingColors, stitchingColors, liningColors);
    }
}