import { ErrorCodes } from "../../helpers/enums";

export class CustomError extends Error {
    originalError?: Error;

    constructor(name: ErrorCodes, message: string, originalError?: Error) {
        super(message);
        this.name = name;
        this.originalError = originalError;
    }
}
