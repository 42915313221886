import { useInjection } from 'inversify-react';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import CountryDropdown from '../../components/dropdown/CountryDropdown';
import Gallery from '../../components/gallery';
import { useLoading } from '../../helpers/contexts';
import { useCountry, } from '../../helpers/contexts/CountryContext';
import { getErrorInformation } from '../../helpers/handlers/GlobalErrorHandler';
import { customComparator } from '../../helpers/utils/StringUtils';
import { DefaultLayout } from "../../layouts";
import { Country } from '../../models/services/Country';
import { GalleryService } from '../../services';
import CountryService from '../../services/CountryService';
import "./home.scss";
import { ErrorPageProps } from '../../models/props/ErrorPageProps';
import ErrorPage from '../error/generic/error';

const Home = ()  => {

    // gallery
    const galleryService = useInjection(GalleryService);
    const [gallery, setGallery] = useState(new Map<string, Array<string>>());
    const menu_options = ["All", ...Array.from(gallery?.keys())].filter(x=> x !== "General").sort(customComparator);
    const [option_active, setOptionActive] = useState('All');

    // country 
    const { country, setSelectedCountry } = useCountry();
    const countryService = useInjection(CountryService);
    const [countryOptions, setCountryOptions] = useState<Country[]>([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    // navigation
    const navigate = useNavigate();


    // contexts
    const { showLoading, hideLoading } = useLoading();

    // error
    const [showError, setShowError] = useState<boolean>(false);
    const [errorProps, setErrorProps] = useState<ErrorPageProps>({title: "Ups!", message: "Something went wrong. Please try again later."});

    // run when the page first loads
    useEffect(() => {
        showLoading();
        galleryService.getGalleryContents()
        .then((response) => {
            setGallery(response);
            countryService.getCountries()
            .then((response) => {
                setCountryOptions(response);
            })
            .catch((error) => {
                const props: ErrorPageProps = getErrorInformation(error);
                setErrorProps(props);
                setShowError(true);
            })
            .finally(() => hideLoading());
        })
        .catch((error) => {
            const props: ErrorPageProps = getErrorInformation(error);
            setErrorProps(props);
            setShowError(true);
        })
        .finally(() => hideLoading());
        // eslint-disable-next-line
    }, []);

    // filter the gallery images by option and number of images
    const filterGallery = (nr_images?: number) : string[] => {
        let filteredImages = [] as string[];
        if (gallery !== null) {
            if (option_active !== 'All') {
                const filtered = gallery?.get(option_active);
                if (filtered) {
                    if (nr_images) {
                        filteredImages = filtered.slice(0, nr_images);
                    }
                    else {
                        filteredImages = filtered;
                    }
                }
            }
            else {
                // return a random selection of images
                if (nr_images){
                    filteredImages = Array.from(gallery.values()).flat()
                    .map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value)
                    .slice(0, nr_images) as string[];
                }
                else {
                    filteredImages = Array.from(gallery.values()).flat()
                    .map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value) as string[];
                }
            }
        }
        return filteredImages;
    }

    // handle the selection of the country
    const handleOnChange = (_event: any, data: any) => {
        const result = countryOptions.find(element => element.name === data.value);
        if (result) {
            setSelectedCountry(result);
        }
    }

    // scroll to the rcc section
    function scrollToRcc() {
        var element = document.getElementById("rcc-section");
        if (element) {
            element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
    }

    return(
        <DefaultLayout 
            navBarProps={{links: [{name: "About", link: "/about"}, {name: "Projects", link: "/portfolio"}, {name: "Contacts", link: "/contacts"}]}}
            showFooter={true}>

            {
                !showError && (
            
                    <div className="main-container">

                        <div className="greetings-section">

                            <img src='/lusomotors.png' alt="Lusomotors_logo"/>

                            <IoIosArrowDown className='arrow' onClick={scrollToRcc}/>

                        </div>

                        <div className='rcc-section' id='rcc-section'>

                            <img className='rcc-logo' src="/rcc.png" alt="RCC Logo"/>

                            <span className='regular-text'>Still creating the most durable and confortable design racing seats</span>
                            <span className='regular-text'>Find the best model for you and fully customize it in our shop</span>

                            <button className="button--alt" onClick={() => {setIsOpen(true)}}>
                                GO TO THE SHOP
                            </button>

                            <div className="gallery">

                                <div className="gallery-menu">
                                    {
                                        menu_options.map((option) => (
                                            <span key={option} id={option} onClick={()=> setOptionActive(option)} className={option_active === option ? "active" : ""}>
                                                {option}
                                            </span>
                                        ))
                                    }
                                </div>
                                
                                <Gallery imagesUrls={filterGallery()}/>

                                <Modal
                                    className='bug--fix'
                                    show={modalIsOpen}
                                    animation={false}
                                    onHide={() => setIsOpen(false)}
                                    >
                                    
                                    <Modal.Header>

                                        <Modal.Title>What is your country?</Modal.Title>
                                    
                                    </Modal.Header>

                                    <Modal.Body>                 
                                    
                                        <p>Please select the country you are ordering from</p>

                                        <CountryDropdown countryOptions={countryOptions} callback={handleOnChange}/>

                                        { country === null && (

                                            <div className='country--not-listed'>
                                                <p>If your country is not listed, please contact us for more shipping information.</p>
                                            </div>
                                        )}

                                        { country !== null && country?.dealer !== null && (

                                            <div className='country--partner'>
                                                <p>The country you selected has an exclusive dealer. Your request will be automatically redirected after model and customization selection.</p>
                                            </div>
                                        )}

                                    </Modal.Body>

                                    <Modal.Footer>

                                        <button className="button" 
                                            onClick={() => {
                                                setIsOpen(false);
                                            }}>
                                            Close
                                        </button>

                                        <button className={"button--alt " + (country === null ? 'no-country' : '')} 
                                            onClick={() => {
                                                if (country !== null) {
                                                    navigate("/seat");
                                                }
                                            }}>
                                            Continue
                                        </button>
                                    
                                    </Modal.Footer>

                                </Modal>

                            </div>

                        </div>

                    </div>
                )
            }

            {
                showError && (
                    
                    <ErrorPage title={errorProps.title} message={errorProps.message}/>
                )
            }

        </DefaultLayout>
    )
}

export default Home;