import { ReactNode } from "react";
import { Footer } from "../../components/footer/Footer";
import NavBar from "../../components/navbar/navBar";
import { NavBarProps } from "../../models/props/NavBarProps";
import CookieConsent from "react-cookie-consent";
import './DefaultLayout.scss';

export interface DefaultLayoutProps {
    navBarProps: NavBarProps,
    children: ReactNode,
    showFooter?: boolean
}

const DefaultLayout = ({navBarProps, children, showFooter}: DefaultLayoutProps) => {
    return (
        <>
    
            <NavBar links={navBarProps.links} activeCompany={navBarProps.activeCompany} />
    
            <div className="App">
                {children}
                {showFooter ? <Footer/> : null}
            </div>

            <CookieConsent buttonWrapperClasses="cookie-consent-button">This website uses cookies to enhance the user experience. By using our site, you agree to our use of cookies. Visit our <a href="/privacy">Privacy Policy</a> to learn more.</CookieConsent>
        </>
        )
};

export default DefaultLayout;