// convert file to base64 string
export function toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (typeof reader.result === 'string' && reader.result.startsWith('data:')) {
                resolve(reader.result.split(',')[1]);
            } 
            else {
                reject(new Error('Invalid base64 data'));
            }
        };
        reader.onerror = error => reject(error);
    });
}