import { Customer } from './Customer';

export class BaquetOrder {
    modelId: number;
    quantity: number;
    sizeId: number;
    finishingId: number;
    finishingRALcode: string | null;
    extrasIds: number[];
    materialId: number;
    paddingId: number;
    headrestId: number | null;
    headrestImage: string | null;
    paddingColorTag: string | null;
    stitchingColorTag: string | null;
    liningColorTag: string | null;
    mountsIds: number[];
    observations: string;
    customer: Customer;
    
    constructor(modelId: number, quantity: number, sizeId: number, finishingId: number, finishingRALcode: string|null, extrasIds: number[], materialId: number, paddingId: number, headrestId: number | null, headrestImage: string|null, paddingColorTag: string|null, stitchingColorTag: string|null, liningColorTag: string|null, mountsIds: number[], observations: string, customer: Customer) {
        this.modelId = modelId;
        this.quantity = quantity;
        this.sizeId = sizeId;
        this.finishingId = finishingId;
        this.finishingRALcode = finishingRALcode;
        this.extrasIds = extrasIds;
        this.materialId = materialId;
        this.paddingId = paddingId;
        this.headrestId = headrestId;
        this.headrestImage = headrestImage;
        this.paddingColorTag = paddingColorTag;
        this.stitchingColorTag = stitchingColorTag;
        this.liningColorTag = liningColorTag;
        this.mountsIds = mountsIds;
        this.observations = observations;
        this.customer = customer;
    }
}